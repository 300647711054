import React, { useState, useEffect } from 'react';
import axios from "axios";

import {Link} from "react-router-dom";
import { useParams } from "react-router-dom";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import '../../assets/css/profil.css';
import pdf from '../../assets/pdf.png';
import MapComponent from '../Containers/MapComponent';
import GraphCAM from '../Containers/graphCAM';
import GraphOTM from '../Containers/graphOTM';
import FormContact from "../Containers/formulaire/FormContact";
import VisiteView from "../Containers/formulaire/visitView";
import TableComponent from '../Containers/tableComponent';
import db from '../../Db/db';

function Profils({ url }) {

    const [popupContact, setPopupContact] = useState(false);
    
    const [loading, setLoading] = useState(true);

    const { id, codeAgence  } = useParams();

    const [visitData, setVisitData] = useState([]);
    const [cityChargData, setCityChargData] = useState([]);
    const [cityLivrData, setCityLivrData] = useState([]);
    const [clientsData, setClientsData] = useState([]);
    const [selectedPrevoir, setSelectedPrevoir] = useState([]);
    const [selectedPrevu, setSelectedPrevu] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [selectedVisit, setSelectedVisit] = useState(null);
    const [teams, setTeam] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [online, setOnline] = useState(true);

    useEffect(() => {
        const checkConnection = async () => {
            try {
                await axios.get('https://api.hautier-transports.fr/crm/');
                setOnline(true); 
            } catch (error) {
                setOnline(false); 
            }
        };
        checkConnection(); 
    }, []);

    useEffect(() => {
        async function fetchData() {
            setLoading(true); 
            const data7 = await db.team.toArray();
            setTeam(data7);
            const data = await db.visites.toArray();
            setVisitData(data);
            const data2 = await db.cityCharg.toArray();
            const data3 = await db.cityLivr.toArray();
            const data4 = await db.clients.toArray();
            setClientsData(data4);
            const data5 = await db.visitePrevoir.toArray();
            const data6 = await db.visitePrevue.toArray();


            if(id.includes('_PARENT')){
                const allenfants =  data4.filter(client => client.codeClient === id && client.codeAgence === codeAgence );
                console.log(allenfants)
                const all = [];
                const all2 = [];
                allenfants.forEach(client => {
                    if (client.enfants && client.enfants.length > 0) {
                        const filteredData = data2.filter(item =>
                            client.enfants.some(enfant =>
                                item.idClient === enfant.enfant && item.codeAgence === enfant.agence
                            )
                        );
                        all.push(...filteredData);
                        const filteredData2 = data3.filter(item =>
                            client.enfants.some(enfant =>
                                item.idClient === enfant.enfant && item.codeAgence === enfant.agence
                            )
                        );
                        all2.push(...filteredData2);
                    }
                });
                setCityChargData(all)
                setCityLivrData(all2)
              }else{
                setCityChargData(data2);
                setCityLivrData(data3);
              }
           
            setSelectedPrevoir(data5.filter(c => c.codeClient === id && c.codeAgence === codeAgence));
            
            setSelectedPrevu(data6.filter(c => c.codeClient === id && c.codeAgence === codeAgence));
            const data8 = await db.contacts.toArray();
            setContacts(data8.filter(c => c.codeClient == id && c.codeAgence == codeAgence));

            setLoading(false); 
        }

        fetchData();
    }, []);
   

    let visitByCom = visitData.filter(visit => visit.codeClient === id && visit.codeAgence === codeAgence ) || [];


    visitByCom.sort((a, b) => new Date(a.dateVisite) - new Date(b.dateVisite));

    let visitePlusProchePasse = visitByCom.find(visit => new Date(visit.dateVisite) < new Date());
    let dataCityCharg = []
    let dataCityDeCharg = []
    if(id.includes('_PARENT')){
        
        dataCityCharg = cityChargData || [];
        dataCityDeCharg = cityLivrData || [];
    }else{

         dataCityCharg = cityChargData.filter(cityChar => cityChar.idClient === id && cityChar.codeAgence === codeAgence) || [];
         dataCityDeCharg = cityLivrData.filter(cityLivr => cityLivr.idClient === id && cityLivr.codeAgence === codeAgence) || [];
    }
    let dataClient = clientsData.filter(client => client.codeClient === id && client.codeAgence === codeAgence ) || [];
    
    let commercial = [] 
    if (teams && dataClient.length > 0 && dataClient[0].commercial) {
        const commercialId = dataClient[0].commercial;
        commercial = teams.filter(team => team.idUser == commercialId) || [];
        
    } 
    
    const downloadFile = (filename) => {
        return axios.get(url + `/uploads/${filename}`, {
            responseType: 'blob',
        });
    };

    const handleDownload = (docUrl) => {
        downloadFile(docUrl)
            .then((response) => {
                const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', docUrl);
                document.body.appendChild(link);

                link.click();

                window.URL.revokeObjectURL(fileUrl);
            })
            .catch((error) => {
                console.error('Erreur lors du téléchargement du fichier :', error);
            });
    };

    const handleNvContact = () => {
        setPopupContact(true)
    }

    const closeContact = () => {
        
        setPopupContact(false)
    }
    const openModal = (visit) => {
        setSelectedVisit(visit);
      setIsOpen(true);
    };
  
    const closeModal = () => {
      setIsOpen(false);
    }
    
    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
           
            {popupContact && <FormContact close={closeContact} client={id} agence={codeAgence} url={url} />}
           {!loading ? (
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                {id.includes('_Prosp') ? (
                    <Title title="Fiche prospect" btn={true}/>
                ) : (
                    <Title title="Fiche client" btn={true} handleExportExcel={false} dataDoc={dataClient} villeCharg={dataCityCharg} villeLivraison={dataCityDeCharg} client={id} agence={codeAgence} commercial={commercial}/>
                )}
                </div>
                <div className="mt-4 h-[85%] w-full">
                    <div className='flex flex-row h-full w-full'>
                        <div className='h-full w-[45%] max-sm:w-[100%] pl-4 text-marineLogo'>
                            {dataClient.map(info => (
                                <div key={info.codeClient} className='h-full w-full'>
                                    <div className="border border-marineLogo rounded bg-blanc shadow pt-4 sm:rounded h-[26%] w-full">
                                        <div className='space-y-4'>
                                            <div className="text-center text-3xl font-bahn uppercase italic">
                                                {info.nomClient}
                                            </div>
                                            <div className='flex justify-between p-2'>
                                                <div>
                                                    <div className='flex space-x-2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                                        </svg>
                                                        <span className='font-bahn'>{info.emailClient}</span>
                                                    </div>
                                                    <div className='flex space-x-2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                                        </svg>
                                                        <span className='font-bahn'>{info.telClient}</span>
                                                    </div>
                                                    <div className='flex space-x-2'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
                                                        </svg>
                                                        <span className='font-bahn'>{info.cpClient} {info.villeClient}, {info.adressClient}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='flex space-x-2'>

                                                        <span className='font-bahn'>
                                                            Code : {id.includes('_PARENT') ? (
                                                                <div>
                                                                    {info && info.enfants ? (
                                                                        <div>
                                                                            {info.enfants.map((enfant, index) => (
                                                                                <span key={index}>
                                                                                    <Link to={"/profil/client/" + enfant.enfant + "/" + enfant.agence}>{enfant.enfant}</Link>
                                                                                    {index !== info.enfants.length - 1 && ', '}
                                                                                </span>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        dataClient.map(code => code.codeClient).join(', ')
                                                                    )}
                                                              </div>
                                                            ) : (
                                                                dataClient.map(code => code.codeClient).join(', ')
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div className='flex space-x-2'>

                                                        <span className='font-bahn'>Site : {info.codeAgence}</span>
                                                    </div>
                                                    <div className='flex space-x-2'>

                                                    {commercial.length > 0 ? (
                                                        <span className='font-bahn'>Commercial : {commercial[0].firstName} {commercial[0].name}</span>
                                                    ) : (
                                                        <span className='font-bahn'>Aucun commercial</span>
                                                    )}
                                                    </div>
                                                    <div className='flex space-x-2'>

                                                        <span className='font-bahn'>Type : {info.typeClient}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="border border-marineLogo rounded bg-blanc shadow sm:rounded w-full mt-[2%] h-[18%] p-2">
                                        <div className='space-y-4 font-bahn flex items-center justify-between'>

                                            <div className=' max-lg:hidden'>
                                                <div className='flex space-x-2 font-bahn'>
                                                    <label>Prochaine Visite à prévoir :</label>
                                                    {selectedPrevoir && selectedPrevoir.length > 0 ? (
                                                        <input
                                                            type="date"
                                                            value={new Date(selectedPrevoir[0].nextVisitDate).toISOString().split('T')[0]}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <span>Aucune visite de prévue</span>
                                                    )}

                                                </div>
                                                <div className='flex space-x-2 font-bahn'>
                                                    <label>Prochaine Visite prévue :</label>
                                                    {selectedPrevu && selectedPrevu.length > 0 ? (
                                                        <input
                                                            type="date"
                                                            value={new Date(selectedPrevu[0].dateVisiteD).toISOString().split('T')[0]}
                                                            readOnly
                                                        />
                                                    ) : (
                                                        <span>Aucune visite de prévue</span>
                                                    )}

                                                </div>

                                                
                                                <div className='flex items-center'>
                                                    <span>Dernière visite : </span>
                                                    {visitByCom && visitByCom.length > 0 ? (
                                                        visitePlusProchePasse && visitePlusProchePasse.urlDoc ? (
                                                            <a href={'https://crm.hautier-transports.fr/uplauds/' + visitePlusProchePasse.urlDoc} target="_blank" rel="noopener noreferrer" download>
                                                                <img className='w-10' src={pdf} alt="Télécharger le PDF" />
                                                            </a>
                                                        ) : (
                                                            <a href='#' onClick={() => openModal(visitePlusProchePasse)} className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-vertLogo font-bold bg-white rounded hover:bg-vertLogo hover:text-blanc focus:ring-4 focus:outline-none">Voir la synthèse</a>
                                                        )
                                                    ) : (
                                                        <span>Aucune visite n'a eu lieu</span>
                                                    )}
                                                </div>
                                                <div>
                                        
                                                    {isOpen && <VisiteView isOpen={isOpen} closs={closeModal} selectedVisit={selectedVisit}/>}
                                                            
                                                </div>



                                            </div>
                                        </div>
                                    </div>
                                    <div className='ml-[5%] border border-marineLogo rounded bg-blanc flex-shrink max-h-[45%] w-[90%] mt-[2%] font-bahn h-[50%] p-2'>
                                        <div className='flex '>
                                            <h2 className='w-[70%]'>Liste des contacts : </h2>
                                            <svg onClick={handleNvContact} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className=" w-8 h-8">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                        </div>

                                        <div className='flex-shrink max-h-[55%]  w-full overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                            {contacts.map(member => (
                                                <div key={member.mailContact} className="w-[70%] bg-white border-b border-bleu m-[1%]">

                                                    <div className="flex flex-row w-full h-full">

                                                        <div className="h-full">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-full">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <ul className="text-xs w-full">
                                                                <li className="w-full rounded-sm">
                                                                    <span className=" w-full font-bahn">{member.nom} {member.prenom} / {member.fonction}</span>
                                                                </li>
                                                                <li className=" w-full rounded-sm">
                                                                    <span className=" w-full font-bahn">{member.mailContact} / {member.telContact}</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <h2>Documents : </h2>
                                        <div className='flex max-h-[40%]  max-w-[100%] overflow-x-auto overflow-y-hidden scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>

                                            {visitByCom.map(visit => (
                                                visit.urlDoc !== null ? (
                                                    <div onClick={() => handleDownload(visit.urlDoc)} key={visit.urlDoc} className='mr-[1%]'>
                                                        <img className='w-20' src={pdf} alt="Télécharger le PDF" />
                                                        <label className='text-[70%]'>{new Date(visit.dateVisite).toISOString().slice(0, 10).replace("T", " ")}</label>
                                                    </div>
                                                ) : null
                                            ))}
                                        </div>
                                    </div>
                                </div>


                            ))}
                        </div>
                        {id.includes('_Prosp') ? (
                            null
                        ) : (
                            <div className='pl-[10%] h-full w-[55%] max-sm:hidden overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
                                
                                <div className='w-[80%] h-[30%] pb-4'>
                                    <GraphCAM client={id} agence={codeAgence} />
                                </div>
                                <div className='w-[80%] h-[30%] pb-4'>
                                    <GraphOTM client={id} agence={codeAgence} />
                                </div>
                                <div className='w-[80%]  h-[60%] border border-marineLogo rounded bg-blanc mb-4'>
                                    <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo flex flex-col'>
                                        <span>Villes de chargements</span>
                                    </div>
                                    <div className='flex h-[80%]'>
                                    { online && (codeAgence === 'BRE') ? (
                                            <>
                                                <div className='w-[10%]'></div>
                                        
                                                <MapComponent villeData={dataCityCharg} nom="chargement" />
                                            </>
                                        ) : (
                                            <TableComponent villeData={dataCityCharg} nom="chargement" />
                                        )}
                                        
                                    </div>
                                </div>
                                <div className='w-[80%] h-[60%] border border-marineLogo rounded bg-blanc mb-4'>
                                    <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo flex flex-col'>
                                        <span>Villes de livraisons</span>
                                    </div>
                                    <div className='flex h-[80%]'>
                                        { online && (codeAgence === 'BRE' ) ? (
                                            <>
                                                <div className='w-[10%]'></div>
                                                
                                                <MapComponent villeData={dataCityDeCharg} nom="livraison" />
                                            </>
                                        ) : (
                                            <TableComponent villeData={dataCityDeCharg} nom="livraison" />
                                        )}
                                        
                                    </div>
                                </div>
                            </div>
                            )}
                    </div>
                </div>
            </div>
        ) :  null}
        </div>
    );
}

export default Profils;