import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export default function ExportExcel({ data, name }) {

    
    const exportToExcel = (data) => {
        if(name === "Mon suivi prospection"){
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(data);
        
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Données');
        
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const fileData = new Blob([excelBuffer], { type: 'application/octet-stream' });
        
            saveAs(fileData, 'exported_data.xlsx');
        }else{
            const anneeCourante = 2024;
            const anneePrecedente = 2023;

            const colonnes = [
                'Code Client', 'Nom Client', 'Code Agence', 'Commercial',
                ...Array.from({ length: 12 }, (_, index) => `CA ${anneePrecedente} ${String(index + 1).padStart(2, '0')}`),
                ...Array.from({ length: 12 }, (_, index) => `CA ${anneeCourante} ${String(index + 1).padStart(2, '0')}`)
            ];

            const donneesFormattees = [];

            data.forEach(item => {
                const ligneData = {
                    'Code Client': item.codeClient,
                    'Nom Client': item.nomClient,
                    'Code Agence': item.codeAgence,
                    'Commercial': item.commercial,
                };


                for (let mois = 1; mois <= 12; mois++) {
                    const moisDataN1 = item.annees[anneePrecedente]?.[mois] || { ca: 0 };
                    ligneData[`CA ${anneePrecedente} ${String(mois).padStart(2, '0')}`] = moisDataN1.ca || 0;
                }
                for (let mois = 1; mois <= 12; mois++) {
                    const moisDataN = item.annees[anneeCourante]?.[mois] || { ca: 0 };
                    ligneData[`CA ${anneeCourante} ${String(mois).padStart(2, '0')}`] = moisDataN.ca || 0;
                }

                donneesFormattees.push(ligneData);
            });

            const ws = XLSX.utils.json_to_sheet(donneesFormattees, { header: colonnes });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Feuille1');

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'exported_data.xlsx');
        
        }
        
    };

    return (
        <button onClick={() => exportToExcel(data)} className='bg-vertLogo w-full flex items-center rounded text-blanc justify-center group hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo py-1'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 group-hover:stroke-vertLogo">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12-3-3m0 0-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>

            Export Excel
        </button>
    );
}
