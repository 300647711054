import React, { useState } from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import Top from '../Containers/Top';
import Histo from '../Containers/Histo';
import Etape from '../Containers/Etapes';


export default function Clients({ url }) {

    const [submitData, setSubmitData] = useState([]);


    const handleSubmit = (submitFilterData) => {
        setSubmitData(submitFilterData)

    };

    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Mes clients"  onSubmitFilter={handleSubmit} />
                </div>
                <div className='flex h-[85%] w-full mt-[3%]'>
                    <div className='w-[70%] max-lg:hidden h-full flex justify-center'>
                        <div className='h-[90%] w-[80%] font-bahn'>
                            <Top submitData={submitData} />
                        </div>
                    </div>
                    <div className=' w-[30%] h-full'>
                        <div className='h-[50%] w-[80%]'>
                            <Histo />
                        </div>
                        <div className='h-[50%] w-[80%]'>
                            <Etape />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
