import React, {useState, useEffect} from 'react';
import axios from "axios";
import {Link} from "react-router-dom";
import logo from "../../assets/logo_blanc.png";


export default function Sidebar() {
    const [open, setOpen] = useState(false);
    
    const [selectedImage, setSelectedImage] = useState(null);

    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);
    const baseUrl = "https://api.hautier-transports.fr/crm/"
    useEffect(() => {

        if(userInfo.user.urlProfil !== ''){
            axios.get(baseUrl+`profilImg/${userInfo.user.urlProfil}`, {
                responseType: 'blob', 
            }).then((response) => {
                const imgBlobURL = URL.createObjectURL(response.data);
    
                setSelectedImage(imgBlobURL)
    
            })
        }


        }, [baseUrl]);

    return (
        
        <div className='max-md:absolute max-md:z-10 h-full max-[1500px]:text-[60%]'>

            <div id={'notdrop'} className={`max-lg:hidden flex flex-col justify-between  top-0 left-0 w-[100%] items-center text-white h-full font-bahn`}>
                <div className='w-full h-[90%]'>
                    <div className={"h-[14%] w-full flex justify-center items-center bg-marineLogo" }>
                            <img className="w-32 max-[1500px]:w-24" src={logo} />
                            
                    </div>
                    <div className={"w-full flex justify-center items-center bg-marineLogo text-blanc pb-2" }>
                        <p className='italic text-lg max-[1500px]:text-sm'>GOLDFINGER</p>
                    </div>
                    <div className="flex flex-col justify-start items-center pt-4 h-[30%] w-full">
                        <div className="flex justify-start flex-col w-full md:w-auto items-start pb-1">
                            <Link to={`/accueil/${userInfo.user.idUser}`} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4" >
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                </svg>
                                <p className="text-marineLogo px-4">Accueil</p>
                                <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                            </Link>
                        </div>
                        <p className="max-[1500px]:text-[80%] max-[1500px]:p-1 text-sm leading-4 uppercase w-full items-center p-2 bg-vertLogo text-blanc text-center">Mon activité commerciale</p>
                            
                        <div className="flex justify-start  flex-col w-full md:w-auto items-start">

                            <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                <Link to={"/agenda"} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>
                                    <p className="text-marineLogo px-4">Mon agenda</p>
                                    <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                </Link>
                            </div>
                            <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                <Link to={"/client/"+userInfo.user.idUser} className="relative flex justify-start items-center pl-3 pr-2 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                </svg>
                                    <p className="text-marineLogo px-4">Mes fiches clients</p>
                                    <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                </Link>
                            </div>
                            <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                <Link to={"/visite/"+userInfo.user.idUser} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                </svg>
                                    <p className="text-marineLogo px-4">Mes visites</p>
                                    <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                </Link>
                            </div>

                        </div>
                    </div>
                    
                    <div className="flex flex-col justify-start w-full h-[16%]">
                        <p className="max-[1500px]:text-[80%] max-[1500px]:p-1 text-sm leading-4 uppercase w-full items-center p-2 bg-roseLogo text-blanc text-center">Mes statistiques</p>
                            
                        <div className="flex justify-start  flex-col w-full md:w-auto items-start pb-1 ">
                            

                            <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                <Link to={"/ca/"+userInfo.user.idUser} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                </svg>

                                    <p className="text-marineLogo px-4">Chiffre d’affaires</p>
                                    <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                </Link>
                            </div>
                            <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                <Link to={"/kpi"} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
                                </svg>

                                    <p className="text-marineLogo px-4">KPI'S</p>
                                    <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                </Link>
                            </div>
                            
                                
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-center w-full h-[30%]">
                    
                        <div>
                            <p className="max-[1500px]:text-[80%] max-[1500px]:p-1 text-sm leading-4 uppercase w-full items-center p-2 bg-bleuLogo text-blanc text-center">Mes outils</p> 
                            <div className="flex justify-start  flex-col w-full md:w-auto items-start pb-1 ">
                            {userInfo.user.role === 'Administrateur' || userInfo.user.idUser === 9 ? (    
                                <>
                                <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                    <Link to={"/conso"} className="relative flex justify-start items-center pl-3 pr-2 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z" />
                                    </svg>

                                        <p className="text-marineLogo px-4">Consolider clients</p>
                                        <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                    </Link>
                                </div>
                                
                                <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                    <Link to={"/affect"} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
                                    </svg>

                                        <p className="text-marineLogo px-4">Affecter clients</p>
                                        <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                    </Link>
                                </div>
                                </>
                            ): ( null)}
                                <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                    <Link to={"/enableProspect"} className="relative flex justify-start items-center pl-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>

                                        <p className="text-marineLogo pl-4">Désactiver prospects</p>
                                        <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                    </Link>
                                </div>
                                
                                <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                    <Link to={"/equipe"} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
                                    </svg>

                                        <p className="text-marineLogo px-4">Mon équipe</p>
                                        <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                    </Link>
                                </div>

                                <div className="flex justify-start flex-col w-full md:w-auto items-start">
                                    <Link to={"/calculatrice"} className="relative flex justify-start items-center px-3 max-[1500px]:py-1 py-2 w-full md:w-52 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#113577" className="w-6 h-6 max-[1500px]:w-4 max-[1500px]:h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
                                    </svg>


                                        <p className="text-marineLogo px-4">Emission de CO²</p>
                                        <div className="border-b-2 absolute bottom-0 left-1/2 w-0 h-1 transition-all duration-300 transform -translate-x-1/2 group-hover:w-full group-hover:h-1 group-hover:border-b-2 group-hover:border-marineLogo"></div>
                                    </Link>
                                </div>

                                    
                            </div>
                        </div>
                     
                     </div>


                </div>
                <div className="flex flex-col w-full h-[10%] ">
                    <Link to={"/profil/"+userInfo.user.idUser} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full py-5 px-2 max-[1500px]:py-2">
                        <div>
                        {selectedImage ? (
                            <img src={selectedImage} className='clip-path-mycircle w-8 h-8' alt="Profile" />
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-8 h-12">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>
                        )}
                    </div>
                        <div>
                            <ul className="text-xs">
                                <li className=" rounded-sm text-marineLogo">
                                    <span className="font-bahn max-[1500px]:text-[80%]">{userInfo.user.firstName} {userInfo.user.name}</span>
                                </li>
                                <li className=" rounded-sm text-marineLogo">
                                    <span className="font-bahn max-[1500px]:text-[80%]">{userInfo.user.title}</span>
                                </li>
                            </ul>
                        </div>
                    </Link>

                </div>
            </div>
            
                {open ? (
                <button
                    className="lg:hidden sm:flex text-3xl text-blanc items-center cursor-pointer fixed left-5 top-3 z-50"
                    onClick={() => setOpen(!open)}
                >
                    x
                </button>
                ) : (
                <svg
                    onClick={() => setOpen(!open)}
                    className="lg:hidden sm:absolute z-10 flex items-center cursor-pointer top-6"
                    fill="#113577"
                    viewBox="0 0 100 80"
                    width="25"
                    height="25"
                >
                    <rect width="100" height="10"></rect>
                    <rect y="30" width="100" height="10"></rect>
                    <rect y="60" width="100" height="10"></rect>
                </svg>
                )}
                <div id={'dropdown'} className={`lg:hidden flex flex-col justify-between  overflow-y-auto top-0 left-0 w-34 bg-marineLogo items-center text-blanc fixed h-full z-40  ease-in-out duration-300 ${open ? "translate-x-50 " : "-translate-x-full"}`}>
                <div className=''>
                    <div className={" mt-8 p-4" }>
                        <div className=" flex justify-center">
                            <img className="h-32" src={logo} />
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-center ">
                        <div className="flex justify-start  flex-col w-full md:w-auto items-start pb-1 ">
                            <Link to={"/accueil/"+userInfo.user.idUser} className="flex justify-start items-center space-x-4 px-3 py-2  w-full md:w-52">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                                </svg>
                                <p>Accueil</p>
                            </Link>
                            <Link to={"/agenda/"+userInfo.user.idUser} className="flex justify-start items-center space-x-4 px-3 py-2  w-full md:w-52">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                                </svg>
                                <p>Mon agenda</p>
                            </Link>
                            <Link to={"/client/"+userInfo.user.idUser} className="flex justify-start items-center space-x-4 px-3 py-2  w-full md:w-52">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                                </svg>
                                <p>Mes fiches clients</p>
                            </Link>
                            <Link to={"/visite/"+userInfo.user.idUser} className="flex justify-start items-center space-x-4 px-3 py-2  w-full md:w-52">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                                </svg>
                                <p>Mes visites</p>
                            </Link>
                        </div>
                    </div>
                    
                </div>
                <div className="flex flex-col  w-full">
                    <Link to={"/profil/"+userInfo.user.idUser} className=" text-left text-white flex justify-start items-center w-full py-5 px-2 ">
                    {selectedImage ? (
                        <img src={selectedImage} className='clip-path-mycircle  w-8 h-8' alt="Profile" />
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-12">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                    )}
                        <ul className="text-xs">
                            <li className=" rounded-sm">
                                <span className="font-bahn max-[1500px]:text-[60%]">{userInfo.user.firstName} {userInfo.user.name}</span>
                            </li>
                            <li className=" rounded-sm">
                                <span className="font-bahn  max-[1500px]:text-[60%]">{userInfo.user.title}</span>
                            </li>
                        </ul>
                    </Link>
                </div>
            </div>
        </div>
    );

}