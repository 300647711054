import React, {useState, useEffect} from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import {registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import {Link} from "react-router-dom";
import VisitePro from '../Containers/VisitePro';
import Modal from 'react-modal';
import db from '../../Db/db';

registerLocale('fr', fr);
setDefaultLocale('fr');
Modal.setAppElement('#root');

export default function Visites() {

    const [selectedPrevoir, setSelectedPrevoir] = useState([]);
    const [submitData, setSubmitData] = useState([]);
    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);
    const [visitesData, setvisitesData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const data = await db.visites.toArray();
            setvisitesData(data.sort((a, b) => new Date(b.dateVisite) - new Date(a.dateVisite)));
            const data2 = await db.visitePrevoir.toArray();
            setSelectedPrevoir(data2);
        }

        fetchData();
    }, []);

    console.log(visitesData)


    const handleSubmit = (submitFilterData) => {
        setSubmitData(submitFilterData)
                                
    };

    let allVisit = []

    if (submitData && submitData.searchTerm !== undefined && submitData.searchTerm != '' ) {
        //nom client
        console.log("1")
        allVisit = visitesData.filter(vi => vi.codeClient === submitData.searchTerm.split(' / ')[0] && vi.codeAgence === submitData.searchTerm.split(' / ')[2])

    } else if (submitData && submitData.commercial !== undefined && submitData.commercial.length > 0 ) {
        //commercial
        console.log("3")
        allVisit = visitesData.filter(vi => vi.idUser == submitData.commercial)

    } else if (submitData && submitData.selectedAgence !== undefined && submitData.selectedAgence.length > 0 ) {
        //agence
        console.log("4")
        allVisit = visitesData.filter(vi => vi.codeAgence === submitData.selectedAgence)

    } else if (submitData && submitData.selectedOptions !== undefined && submitData.selectedOptions.length > 0) {
        //activite
        console.log("5")
        allVisit = visitesData.filter(client => client.idUser == userInfo.user.idUser);
    } else{
        //toutes les visites
        console.log("6")
        allVisit = visitesData;
    }

    console.log(submitData)

    

    return (
        <div className='flex flex-row h-full w-full fixed'>

            <Sidebar />

            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">

                    <Title title="Mes Visites" btn={true} reporting={true} onSubmitFilter={handleSubmit}/>

                </div>
                <div className='flex h-[85%] w-full mt-[3%] px-4'>

                    <div className=" w-[70%] max-sm:w-[100%] h-full">

                        <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-full w-[100%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-[90%]'>
                            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                                <span>Mes visites passées</span>
                            </div>
                            <div className='flex items-center justify-center'>
                                <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[98%] border-collapse ">
                                    <thead className='h-[6%] max-h-1.5 text-blanc'>
                                        <tr className='bg-marineLogo'>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Nom client</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Date du RDV</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"></th>
                                        </tr>
                                    </thead>
                                    <tbody className='overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                        {allVisit.map(visit => (  
                                        <tr key={visit.id} className='bg-white'>
                                            <td className=" border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left"><Link to={"/profil/client/" + visit.codeClient + "/" + visit.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]" > <span>{visit.nomClient}</span></Link></td>
                                            <td className=" border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">{new Date(visit.dateVisite).toISOString().slice(0, 10).replace("T", " ")}</td>
                                            <td className=" border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">
                                            <Link to={"/newVisite/"+visit.id}  className="inline-flex items-center px-4 py-2 text-sm font-medium text-blanc text-vertLogo font-bold bg-blanc rounded-lg hover:bg-vertLogo hover:text-blanc focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700">Synthèse</Link>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col p-8 w-[30%] h-full max-sm:hidden">
                    <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-[50%] w-[100%] mb-[5%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
                            
                            <div className='flex justify-center border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                                <p>Mes visites à prévoir</p>
                            </div>
                            <div className='flex-shrink max-h-[90%]  w-full mt-[5%] font-bahn overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                {selectedPrevoir.map(client => (
                                    <div className="w-full bg-white border-b border-bleu m-[1%]">
                                        <div className="flex flex-row w-full h-full">
                                        <Link to={"/profil/client/" + client.codeClient + "/" + client.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                                                <div className="h-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-8 h-full">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <ul className="text-xs w-full text-marineLogo">
                                                        <li className="w-full rounded-sm">
                                                            <span className=" w-full font-bahn">{client.nomClient}</span>
                                                        </li>
                                                        <li className=" w-full rounded-sm">
                                                            <span className=" w-full font-bahn">{client.codeClient}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <VisitePro />
                        
                    </div>
                </div>
            </div> 
        </div>
    );
}