import React, { useState, useEffect } from 'react';
import axios from "axios";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';

import FormEffectClient from "../Containers/formulaire/FormEffectClient"

function Conso({ url }) {
    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);
    const [affect, setAffect] = useState([])
    
    const [submitData, setSubmitData] = useState([]);


    useEffect(() => {
        getClients(submitData);
     }, [url]);

    const handleSubmit = (submitFilterData) => {
        getClients(submitFilterData);
        setSubmitData(submitFilterData)

    };



    function getClients(submitData){
        axios.get(url+'client/', {
            params: {
                searchTerm: submitData.searchTerm,
                commercial: submitData.commercial,
                selectedAgence: submitData.selectedAgence
            },
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {       
            setAffect(res.data);
        })
        .catch(error=>{
            console.log(error);
            
        });
    }

     const handleDisableClick = (codeClient, codeAgence) => {
        console.log(codeClient)
        console.log(codeAgence)
        axios.put(url+"client/disabledAffect",{
            codeClient: codeClient,
            codeAgence: codeAgence
        }, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .catch(error => {
            console.log(error);
        })
        getClients(submitData)
      }

      function onSubmitSuccess (){
        getClients(submitData)
      }


   
    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Affectations Clients" btn={false}  onSubmitFilter={handleSubmit}/>
                </div>
                <div className="p-2 h-[85%] w-full flex flex-row">
                    <div className='w-[80%] h-full font-bahn '>
                        <div className='flex flex-col h-full w-full'>
                            <div className='h-[4%] w-full'>
                                <table className="table-fixed h-[4%] border-collapse items-center bg-transparent w-[80%] ">
                                    <thead className='h-full max-h-1.5'>
                                        <tr className='bg-marineLogo text-blanc'>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Commercial</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Client</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Agence</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Dissocier</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div className='h-[80%] w-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>                
                                <table className="table-fixed h-[96%] border-collapse items-center bg-transparent w-[80%]">
                                    <tbody className='max-h-full h-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                        {affect.map((affect, index) => (
                                            <tr key={index} className='h-[4%] border border-b-1 border-l-0 border-r-0 border-marineLogo'>
                                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap "> {affect.firstName} {affect.name}</td>
                                                <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-marineLogo">{affect.nomClient}<br />  <span className='text-noir'>({affect.codeClient})</span></td>
                                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">{affect.nom}</td>
                                                <td><svg onClick={() => handleDisableClick(affect.codeClient, affect.codeAgence)}  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='w-[20%] h-full flex justify-end'>
                        <FormEffectClient url={url} token={userInfo.token} onSubmitSuccess={onSubmitSuccess}  />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Conso;


/**
 * 
 */
