import React, { useState, useEffect } from 'react';
import axios from "axios";
function FormRemoveProsp({onClose, url, token}) {
    
    const [currentClient, setCurrentClient] = useState('');
    const [currentProspect, setCurrentProspect] = useState('');
    const [clients, setClients] = useState([]);

    useEffect(() => {
        
        axios.get(url+'/client/all/', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {         
            setClients(res.data);
            
        }).catch(error=>{
            console.log(error);
            
        });
        
    }, [url]);

    const submit = (e) => {
        e.preventDefault();
        const client = e.target.client.value
        const codeClient = client.split(' - ')[0]
        const agenceClient = client.split(' - ')[1]

        const prospect = e.target.prospect.value
        const codeProsp = prospect.split(' - ')[0]
        const agencProsp = prospect.split(' - ')[1]

        axios.put(url+'client/disableProspect', {
            codeProsp: codeProsp,
            agencProsp: agencProsp,
            codeClient: codeClient,
            agenceClient: agenceClient
        },{
        headers: { 
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            'Authorization': 'Bearer ' + token
      }
      }).then(response => {
        alert('Le prospect à bien été désactivé!');
        onClose();
    }).catch(function (error) {
        alert('Une erreur s\'est produite lors de la requête.');
        console.log(error);
    });
        

        onClose()
    }
    return(
        <div className="w-full flex h-[90%]">
           <div className="bg-blanc w-full max-lg:w-full p-4 rounded-lg shadow-lg border border-marineLogo min-h-[20%] max-h-[80%] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100">
                <h2 className="text-[100%] font-semibold text-marineLogo mb-4">Formulaire Consolider Prospect</h2>
                <form onSubmit={submit} className='flex flex-col' id='monFormulaire'>
                    <div>
                        <div className="mb-4">
                                
                            <label htmlFor="client" className="block text-sm font-medium text-grey">Le client :</label>
                            <div className='flex items-center mb-4'>
                                <div className=' w-[90%]'>
                                    <input list="clientctList"
                                        name="client"
                                        id="client"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={currentClient}
                                        onChange={(e) => setCurrentClient(e.target.value)}
                                    />
                                    <datalist id="clientctList">
                                        {clients.filter((client) => client.typeClient === 'client-effectif').map((client, index) => (
                                            <option key={index} value={`${client.codeClient} - ${client.codeAgence} - ${client.nomClient}`} />
                                        ))}
                                    </datalist>
                                </div>
                            </div>
                                
                        </div>
                        <div className="mb-4">
                                
                            <label htmlFor="prospect" className="block text-sm font-medium text-grey">Le prospect :</label>
                            <div className='flex items-center mb-4'>
                                <div className=' w-[90%]'>
                                    <input list="prospectList"
                                        name="prospect"
                                        id="prospect"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={currentProspect}
                                        onChange={(e) => setCurrentProspect(e.target.value)}
                                    />
                                    <datalist id="prospectList">
                                        {clients.filter((client) => client.typeClient === 'prospect').map((client, index) => (
                                            <option key={index} value={`${client.codeClient} - ${client.codeAgence} - ${client.nomClient}`} />
                                        ))}
                                    </datalist>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                    <div className="flex justify-end">
                    <button
                        type="button"
                        className="hover:border hover:border-roseLogo hover:bg-blanc hover:text-roseLogo mr-2 px-4 py-1 rounded-md border border-marineLogo text-marineLogo bg-blanc focus:outline-none"
                        onClick={onClose}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-vertLogo w-[30%] flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                    >
                        Consolider
                    </button>
                </div>
                </form>
            </div>
        </div>
    )
}
export default FormRemoveProsp