import React, { useRef, useEffect, useState } from 'react';
import useOnlineStatus from '../Containers/useOnlineStatus';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import db from '../../Db/db';
import axios from "axios";

export default function Clients({ url }) {

  const isOnline = useOnlineStatus();
  const json = localStorage.getItem("token");
  const userInfo = JSON.parse(json);
  const [clients, setClients] = useState([]);
  const [visites, setVisites] = useState([]);
  const [etapes, setEtapes] = useState([]);
  const [formData, setFormData] = useState({
    adressEnt: '', adresse2: '', villeEnt: '', cpEnt: '', numEnt: '', emailEnt: '', ca: '',
    activite: '', appel1: '', appel2: '', appel3: '', appel4: '', 
    commentaire: '',  nextVisitDate: '',
    nomClient: '', civile: '', nom: '', prenom: '', fonction: '', idCom: userInfo.user.idUser, type: 'prospect', site: userInfo.user.agency
  });
  const [visitDate, setvisitDate] = useState('')
  const [etap, setEtap] = useState({ text: '', date: '' });
  async function fetchData() {
    const data1 = (await db.clients.toArray());
    setClients(data1.filter(cl => cl.typeClient === "prospect"));
    const data2 = await db.visites.toArray()
    setVisites(data2)
    const data3 = await db.etapes.toArray();
    setEtapes(data3)  
  }


  useEffect(() => {
    
    fetchData();

  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "visitDate") {
      setvisitDate(value);
    } else if (name === "text" || name === "date") {
      setEtap((prevEtap) => ({
        ...prevEtap,
        [name]: value,
      }));
    } else if (name === 'nomClient' && value.includes(' / ')) {
      const [codeClient, nomClient, codeAgence] = value.split(' / ');
      const prospect = clients.find(cl => cl.codeClient === codeClient && cl.codeAgence === codeAgence);
      if (prospect) {
        setFormData({
          ...formData,
          adressEnt: prospect.adressClient,
          adresse2: prospect.adresse2,
          villeEnt: prospect.villeClient,
          cpEnt: prospect.cpClient,
          numEnt: prospect.telClient,
          emailEnt: prospect.emailClient,
          ca: prospect.caAnnuel,
          activite: prospect.activite,
          codeClient: codeClient,
          nomClient: nomClient,
          site: codeAgence
        });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async () => {
    
    console.log(etap)
    console.log(formData)
    try {
      if (isOnline) {
        // Envoyer les données au serveur
        const response = await axios.post(
          'https://api.hautier-transports.fr/crm/visit/createUpd',
          {
            formData: formData,
            etapes: [etap],
            materials: [],
            deps: [],
            url: null,
            docu: null,
            visitDate: visitDate ? visitDate : null,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": 'multipart/form-data',
              'Authorization': 'Bearer ' + userInfo.token
            }
          }
        );
        console.log('Réponse du serveur:', response.data);
        alert('La visite a bien été enregistrée');
        
        localStorage.removeItem('New_visit');
      } else {
        const offlineData = JSON.parse(localStorage.getItem('New_visit')) || [];
        offlineData.push({
          formData,
          etapes: [etap],
          materials: [],
          deps: [],
          url: null,
          docu: null,
          visitDate: visitDate ? visitDate : null,
        });
        localStorage.setItem('New_visit', JSON.stringify(offlineData));
        alert('Vous êtes hors ligne. Les données ont été sauvegardées localement.');
      }
      
      const maxId = await db.visites.orderBy('id').last();
      let newId = maxId ? maxId.id + 1 : 1;
      
      await db.visites.put({
        id: newId,
        name: userInfo.user.name,
        firstname: userInfo.user.firstName,
        idUser: userInfo.user.idUser,
        dateVisite: visitDate,
        action: formData.topics,
        urlDoc: '',
        nextVisitDate: formData.nextVisitDate,
        codeClient: formData.codeClient,
        nomClient: formData.nomClient,
        secteur: formData.secteur,
        codeAgence: formData.site,
        typeClient: formData.type,
        adressClient: formData.adressEnt,
        cpClient: formData.cpEnt,
        villeClient: formData.villeEnt,
        paysClient: formData.paysEnt,
        telClient: formData.numEnt,
        emailClient: formData.emailEnt,
        budgetTL: formData.budget,
        caAnnuel: formData.ca,
        nom: formData.nom,
        prenom: formData.prenom,
        mailContact: formData.email,
        telContact: formData.num,
        fonction: formData.fonction,
        commentaireaire: formData.commentaire,
        appel1: formData.appel1,
        appel2: formData.appel2,
        appel3: formData.appel3,
        appel4: formData.appel4
      });
  
      await db.clients.put({
        codeClient: formData.codeClient,
        codeAgence: formData.site,
        nomClient: formData.nomClient,
        adressClient: formData.adressEnt,
        adresse2: formData.adresse2,
        cpClient: formData.cpEnt,
        villeClient: formData.villeEnt,
        paysClient: formData.paysEnt,
        telClient: formData.numEnt,
        emailClient: formData.emailEnt,
        budgetTL: formData.budget,
        caAnnuel: formData.ca,
        activite: formData.activite,
        typeClient: formData.type,
        commercial: userInfo.user.idUser,
        enfant: null
      });
  
      const maxIdC = await db.contacts.orderBy('id').last();
      const newIdC = maxIdC ? maxIdC.id + 1 : 1;
      await db.contacts.put({
        id: formData.idContact || newIdC,
        codeClient: formData.codeClient,
        codeAgence: formData.site,
        nom: formData.nom,
        prenom: formData.prenom,
        mailContact: formData.email,
        telContact: formData.num,
        tel2Contact: null,
        fonction: formData.fonction,
        dateVisite: null,
        updateDate: null,
        isActive: 1
      });
  
      await fetchData();
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire:', error);
      alert('Erreur lors de la soumission du formulaire');
    }
  };

  const fixedColumns = [
    "Raison Sociale", "Civilité", "Nom", "Prénom", "Fonction"
  ];

  const scrollableColumns = [
    "Ligne adresse 1", "Ligne adresse 2", "Ville", "Code Postal", 
    "Numéro de téléphone", "Adresse Email", "Chiffre d’affaires", 
    "Activité", "1er appel", "2ème appel", "3ème appel", 
    "4ème appel", "Date et heure RDV", "commentaireaire", 
    "Prochaine étape", "Date prochaine étape", "Prochain RDV", ""
  ];

  const scrollContainer1Ref = useRef(null);
  const scrollContainer2Ref = useRef(null);

  const handleScroll1 = () => {
    if (scrollContainer2Ref.current) {
      scrollContainer2Ref.current.scrollTop = scrollContainer1Ref.current.scrollTop;
    }
  };

  const handleScroll2 = () => {
    if (scrollContainer1Ref.current) {
      scrollContainer1Ref.current.scrollTop = scrollContainer2Ref.current.scrollTop;
    }
  };

  useEffect(() => {
    const scrollContainer1 = scrollContainer1Ref.current;
    const scrollContainer2 = scrollContainer2Ref.current;

    if (scrollContainer1 && scrollContainer2) {
      scrollContainer1.addEventListener('scroll', handleScroll1);
      scrollContainer2.addEventListener('scroll', handleScroll2);

      return () => {
        scrollContainer1.removeEventListener('scroll', handleScroll1);
        scrollContainer2.removeEventListener('scroll', handleScroll2);
      };
    }
  }, []);

  const headerRef = useRef(null);

  useEffect(() => {
    const scrollContainer = scrollContainer2Ref.current;
    const header = headerRef.current;

    if (scrollContainer) {
      const handleScroll = () => {
        if (header) {
          header.scrollLeft = scrollContainer.scrollLeft;
        }
      };

      scrollContainer.addEventListener('scroll', handleScroll);

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  let visite = [];
  clients.forEach(cli => {
    const filteredVisites = visites.filter(v => v.codeClient === cli.codeClient && v.codeAgence === cli.codeAgence);
    visite = visite.concat(filteredVisites);
    console.log(visite)
  });

  const dataForExcel = visites.map(visite => {
    const filteredEtapes = etapes.filter(etape => etape.idVisite === visite.id);
    const etapeSujet = filteredEtapes.length > 0 ? filteredEtapes[0].sujet : '';
    const etapeDate = filteredEtapes.length > 0 ? new Date(filteredEtapes[0].date).toLocaleDateString() : '';

    return {
      'Nom Client': visite.nomClient,
      'Civilité': visite.civilite,
      'Nom': visite.nom,
      'Prénom': visite.prenom,
      'Fonction': visite.fonction,
      'Adresse': visite.adressClient,
      'Adresse 2': visite.adresse2,
      'Ville': visite.villeClient,
      'Code Postal': visite.cpClient,
      'Téléphone': visite.telClient,
      'Email': visite.emailClient,
      'Chiffre d’affaires': visite.caAnnuel,
      'Activité': visite.activite,
      'Appel 1': visite.appel1,
      'Appel 2': visite.appel2,
      'Appel 3': visite.appel3,
      'Appel 4': visite.appel4,
      'Date Visite': new Date(visite.dateVisite).toLocaleDateString(),
      'Commentaire': visite.commentaire,
      'Prochaine étape': etapeSujet,
      'Date prochaine étape': etapeDate,
      'Prochain RDV': new Date(visite.nextVisitDate).toLocaleDateString(),
      'Commercial': `${visite.firstname} ${visite.name}`
    };
  });

  return (
    <div className='flex flex-row h-full w-full fixed'>
      <Sidebar />
      <div className="relative flex flex-col w-full h-full bg-bleuBlanc overflow-hidden">
        <div className="h-[15%] w-full">
          <Title title="Mon suivi prospection" handleExportExcel={dataForExcel} />
        </div>
        <div className='flex h-[85%] w-full mt-[2%] overflow-y-auto'>
          <div className='flex flex-col w-[40%] h-full'>
            <div className='h-[6%]'>
              <table className='w-full h-full bg-marineLogo text-blanc table-fixed'>
                <thead>
                  <tr>
                    {fixedColumns.map((col, index) => (
                      <th key={index} className='px-2 py-2 whitespace-nowrap w-28'>{col}</th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <div className='h-[88%] overflow-y-auto overflow-x-hidden' ref={scrollContainer1Ref}>
              <div>
              <table className='w-full h-full table-fixed'>
                <tbody>
                  <tr className=''>
                    <td className='py-2 whitespace-nowrap text-center w-28 '>
                      <input type='text' list='raisonSociale' name='nomClient' onChange={handleInputChange} value={formData.nomClient} placeholder='Entreprise' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-full'/>
                      <datalist id='raisonSociale' className='absolute w-[200px]'>
                        { clients.map(c => (
                          
                          <option className='w-full' key={c.codeClient + ' / ' + c.codeAgence} value={c.codeClient + ' / ' + c.nomClient + ' / ' + c.codeAgence}>{c.codeClient} {c.nomClient} {c.codeAgence}</option>
                        ))}
                      </datalist>
                    </td>
                    <td className='py-2 whitespace-nowrap text-center w-28'>
                      <input type='text' name='civile' value={formData.civile} onChange={handleInputChange} placeholder='Civilité' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                    </td>
                    <td className='py-2 whitespace-nowrap text-center w-28'>
                      <input type='text' name='nom' value={formData.nom} onChange={handleInputChange} placeholder='Nom' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                    </td>
                    <td className='py-2 whitespace-nowrap text-center w-28'>
                      <input type='text' name='prenom' value={formData.prenom} onChange={handleInputChange} placeholder='Prenom' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                    </td>
                    <td className='py-2 whitespace-nowrap text-center w-28'>
                      <input type='text' name='fonction' value={formData.fonction} onChange={handleInputChange} placeholder='Fonction' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/>
                    </td>
                  </tr>
                  {visite.map((visite, visiteI) => (
                      <tr key={visiteI} className='h-9 border border-x-0 border-b-1 text-center '>
                        <td className='text-xs'>{visite.nomClient}</td>
                        <td className='text-xs'>{visite.civilite}</td>
                        <td className='text-xs'>{visite.nom}</td>
                        <td className='text-xs'>{visite.prenom}</td>
                        <td className='text-xs'>{visite.fonction}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
          <div className='flex flex-col w-[60%] h-full'>
            <div ref={headerRef} className='h-[6%] w-full overflow-x-hidden'>
              <table className='w-full h-full bg-marineLogo text-blanc table-fixed'>
                <thead>
                  <tr>
                    {scrollableColumns.map((col, index) => (
                      <th key={index} className='px-2 py-2 whitespace-nowrap w-40'>{col}</th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <div className='h-[88%] w-full overflow-y-auto overflow-x-auto' ref={scrollContainer2Ref}>
              <div className='w-full min-w-[1800px]'>
                <table className='w-full h-full table-fixed'>
                  <tbody>
                    <tr>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='adressEnt' value={formData.adressEnt} onChange={handleInputChange} placeholder='Adresse' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='adresse2' value={formData.adresse2} onChange={handleInputChange} placeholder='Adresse' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='villeEnt' value={formData.villeEnt} onChange={handleInputChange} placeholder='Ville' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='cpEnt' value={formData.cpEnt} onChange={handleInputChange} placeholder='Code Postale' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='numEnt' value={formData.numEnt} onChange={handleInputChange} placeholder='Téléphone' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='emailEnt' value={formData.emailEnt} onChange={handleInputChange} placeholder='Email' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='ca' value={formData.ca} onChange={handleInputChange} placeholder='CA' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='activite' value={formData.activite} onChange={handleInputChange} placeholder='Activité' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='appel1' value={formData.appel1} onChange={handleInputChange} placeholder='Appel 1' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='appel2' value={formData.appel2} onChange={handleInputChange} placeholder='Appel 2' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='appel3' value={formData.appel3} onChange={handleInputChange} placeholder='Appel 3' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='appel4' value={formData.appel4} onChange={handleInputChange} placeholder='Appel 4' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='date' name='visitDate' value={visitDate.visitDate} onChange={handleInputChange} className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='commentaire' value={formData.commentaire} onChange={handleInputChange} placeholder='commentaireaire' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='text' name='text' value={etap.text} onChange={handleInputChange} placeholder='Prochaine Etapes' className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='date' name='date' value={etap.date} onChange={handleInputChange} className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='date' name='nextVisitDate' value={formData.nextVisitDate} onChange={handleInputChange} className='rounded-md border border-marineLogo focus:outline-none hover:border hover:border-vertLogo focus:border focus:border-vertLogo w-[98%]'/></td>
                      <td className='py-2 whitespace-nowrap text-center w-40'><input type='button' onClick={handleSubmit} value='Ajouter'  className=" mr-2 w-full bg-vertLogo flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo"/></td>
                    </tr>
                    {visite.map((visite, visiteI) => (
                      <tr key={visiteI} className='h-9 border border-x-0 border-b-1 text-center '>
                        <td className='text-xs'>{visite.adressClient}</td>
                        <td className='text-xs'>{visite.adresse2}</td>
                        <td className='text-xs'>{visite.villeClient}</td>
                        <td className='text-xs'>{visite.cpClient}</td>
                        <td className='text-xs'>{visite.telClient}</td>
                        <td className='text-xs'>{visite.emailClient}</td>
                        <td className='text-xs'>{visite.caAnnuel}</td>
                        <td className='text-xs'>{visite.activite}</td>
                        <td className='text-xs'>{visite.appel1}</td>
                        <td className='text-xs'>{visite.appel2}</td>
                        <td className='text-xs'>{visite.appel3}</td>
                        <td className='text-xs'>{visite.appel4}</td>
                        <td className='text-xs'>{String(new Date(visite.dateVisite).getDate()).padStart(2, '0')}/{String(new Date(visite.dateVisite).getMonth() + 1).padStart(2, '0')}/{new Date(visite.dateVisite).getFullYear()}</td>
                        <td className='text-xs'>{visite.commentaire}</td>
                        {(() => {
                          const filteredEtapes = etapes.filter(etape => etape.idVisite === visite.id);
                          if (filteredEtapes.length > 0) {
                            return (
                              <>
                                <td className='text-xs'>{filteredEtapes[0].sujet}</td>
                                <td className='text-xs'>
                                  {String(new Date(filteredEtapes[0].date).getDate()).padStart(2, '0')}/
                                  {String(new Date(filteredEtapes[0].date).getMonth() + 1).padStart(2, '0')}/
                                  {new Date(filteredEtapes[0].date).getFullYear()}
                                </td>
                              </>
                            );
                          } else {
                            return (
                              <>
                                <td className='text-xs'></td>
                                <td className='text-xs'></td>
                              </>
                            );
                          }
                        })()}
                        <td className='text-xs'>{String(new Date(visite.nextVisitDate).getDate()).padStart(2, '0')}/{String(new Date(visite.nextVisitDate).getMonth() + 1).padStart(2, '0')}/{new Date(visite.nextVisitDate).getFullYear()}</td>
                        <td className='text-xs'>{visite.firstname} {visite.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
