import React, {useState, useEffect} from 'react';
function VisiteView({isOpen, closs, selectedVisit}) {
console.log(selectedVisit)
    const [formData, setFormData] = useState({
        idCom: selectedVisit.idUser,
        nom: selectedVisit.nom,
        prenom: selectedVisit.prenom,
        fonction: selectedVisit.fonction,
        email: selectedVisit.mailContact,
        num: selectedVisit.telContact,
        topics: selectedVisit.action,
        type: selectedVisit.typeClient,
        entreprise: selectedVisit.nomClient,
        secteur: null,
        site: null,
        ca: selectedVisit.caAnnuel,
        budget: selectedVisit.budgetTL,
        adressEnt: selectedVisit.adressClient,
        villeEnt: selectedVisit.villeClient,
        cpEnt: selectedVisit.cpClient,
        paysEnt: selectedVisit.paysClient,
        emailEnt: null,
        numEnt: null,
        codeClient: selectedVisit.codeClient,
        nextVisitDate: null,
        idContact: null
    });

    if (!isOpen) {
        return null;
    }
    const handleChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }
    const handleSubmit = () => {
        console.log(formData)
    }

    return(
        <div>
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-blanc w-[50%] p-4 rounded-lg shadow-lg border border-marineLogo min-h-[20%] max-h-[80%] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100">
                    <form id={selectedVisit.id} onSubmit={handleSubmit}>
                        <fieldset className="mb-4">
                            <legend className="text-lg font-semibold mb-2 text-marineLogo">Client / Prospect</legend>
                            <div className='flex'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="type" className="block text-sm font-medium text-grey">
                                        Type:
                                    </label>
                                    <input
                                        type="text"
                                        name="type"
                                        id="type"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.type}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="codeClient" className="block text-sm font-medium text-grey">
                                        Code Client / Prospect:
                                    </label>
                                    <input
                                        type="text"
                                        name="codeClient"
                                        id="codeClient"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.codeClient}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="entreprise" className="block text-sm font-medium text-grey">
                                        Nom de l'entreprise:
                                    </label>
                                    <input
                                        type="text"
                                        name="entreprise"
                                        id="entreprise"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.entreprise}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="adressEnt" className="block text-sm font-medium text-grey">
                                        Addresse:
                                    </label>
                                    <input
                                        type="text"
                                        name="adressEnt"
                                        id="adressEnt"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.adressEnt}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="villeEnt" className="block text-sm font-medium text-grey">
                                        Ville:
                                    </label>
                                    <input
                                        type="text"
                                        name="villeEnt"
                                        id="villeEnt"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.villeEnt}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="cpEnt" className="block text-sm font-medium text-grey">
                                        Code Postal:
                                    </label>
                                    <input
                                        type="text"
                                        name="cpEnt"
                                        id="cpEnt"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.cpEnt}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="paysEnt" className="block text-sm font-medium text-grey">
                                        Pays:
                                    </label>
                                    <input
                                        type="text"
                                        name="paysEnt"
                                        id="paysEnt"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.paysEnt}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="ca" className="block text-sm font-medium text-grey">
                                        CA Annuel:
                                    </label>
                                    <input
                                        type="text"
                                        name="ca"
                                        id="ca"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.ca}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="budget" className="block text-sm font-medium text-grey">
                                        Budget TL:
                                    </label>
                                    <input
                                        type="text"
                                        name="budget"
                                        id="budget"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.budget}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                    
                        </fieldset>
                        <fieldset className="mb-4">
                            <legend className="text-lg font-semibold mb-2 text-bleu">Contact</legend>
                            <div className='flex'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="nom" className="block text-sm font-medium text-grey">
                                        Nom:
                                    </label>
                                    <input
                                        type="text"
                                        name="nom"
                                        id="nom"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.nom}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="prenom" className="block text-sm font-medium text-grey">
                                        Prenom:
                                    </label>
                                    <input
                                        type="text"
                                        name="prenom"
                                        id="prenom"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.prenom}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="fonction" className="block text-sm font-medium text-grey">
                                        Fonction:
                                    </label>
                                    <input
                                        type="text"
                                        name="fonction"
                                        id="fonction"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.fonction}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="email" className="block text-sm font-medium text-grey">
                                        Email:
                                    </label>
                                    <input
                                        type="text"
                                        name="email"
                                        id="email"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="num" className="block text-sm font-medium text-grey">
                                        Numéro:
                                    </label>
                                    <input
                                        type="text"
                                        name="num"
                                        id="num"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={formData.num}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="mb-4">
                            <legend className="text-lg font-semibold mb-2 text-bleu">La visite</legend>
                            <div className='flex'>
                                <div className="mb-4 w-[80%]">
                                    <label htmlFor="visitDate" className="block text-sm font-medium text-grey">
                                        Date de la visite:
                                    </label>
                                    <input
                                        type="date"
                                        name="visitDate"
                                        id="visitDate"
                                        className="mt-1 block w-[30%] rounded-md border border-marineLogo shadow-sm hover:border hover:border-vertLogo focus:border focus:border-vertLogo focus:outline-none"
                                        value={new Date(selectedVisit.dateVisite).toISOString().slice(0, 10).replace("T", " ")}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="mb-4 w-[80%]">
                                    <label htmlFor="topics" className="block text-sm font-medium text-grey">
                                        Sujets Evoqués:
                                    </label>
                                    
                                    <textarea 
                                        name="topics" 
                                        id="topics" 
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"  
                                        rows="10" 
                                        value={formData.topics}>
                                    </textarea>
                                </div>
                            </div>
                            <div className='flex'>
                                <div className="mb-4 w-[80%]">
                                    <label htmlFor="etape" className="block text-sm font-medium text-grey">
                                        Prochaines étapes:
                                    </label>
                                    {selectedVisit.etapes_sujet ? (
                                    <div className='flex flex-row'>
                                        
                                        <ul className=' mr-[3%]'>
                                            {selectedVisit.etapes_sujet.split(',').map((etape, index) => (
                                                <li key={index}>{etape}</li>
                                            ))}
                                        </ul>
                                        <ul>
                                            {selectedVisit.etapes_date.split(',').map((etape, index) => (
                                                <li key={index}>{etape}</li>
                                            ))}
                                        </ul>
                                    </div>
                                    
                                    ) : null}
                                </div>
                            </div>
                            <div className='flex'>
                                
                                <div className="mb-4 w-[80%]">
                                    <label htmlFor="nextVisitDate" className="block text-sm font-medium text-grey">
                                        Prochaine visite:
                                    </label>
                                    <input
                                        type="date"
                                        name="nextVisitDate"
                                        id="nextVisitDate"
                                        className="mt-1 block w-[30%] rounded-md border border-marineLogo shadow-sm hover:border hover:border-vertLogo focus:border focus:border-vertLogo focus:outline-none"
                                        value={new Date(selectedVisit.nextVisitDate).toISOString().slice(0, 10).replace("T", " ")}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        
                        </fieldset>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="hover:border hover:border-roseLogo hover:bg-blanc hover:text-roseLogo mr-2 px-4 py-1 rounded-md border border-marineLogo text-marineLogo bg-blanc focus:outline-none"
                            >
                                Modifier
                            </button>
                            <button
                                type="button"
                                className="hover:border hover:border-roseLogo hover:bg-blanc hover:text-roseLogo mr-2 px-4 py-1 rounded-md border border-marineLogo text-marineLogo bg-blanc focus:outline-none"
                                onClick={closs}
                            >
                                Fermer
                            </button>
            
            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default VisiteView