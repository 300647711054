import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title as ChartTitle, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import db from '../../Db/db';

ChartJS.register(CategoryScale, LinearScale, PointElement,LineElement, BarElement, Tooltip, ChartTitle, Legend)


function GraphCAM({ client, agence, clientList, dateDu, dateAu, periodeEquivalente }) {
  const [camData, setcamData] = useState([]);
  const [clientsData, setClientsData] = useState([]);
  
  const json = localStorage.getItem("token");
  const userInfo = JSON.parse(json);

  useEffect(() => {
    async function fetchData() {
      const data2 = await db.top.toArray();
      setcamData(data2);
      const data4 = await db.clients.toArray();
      setClientsData(data4);
    }
    fetchData();
  }, []);

  const calculerSommeParMois = (dataArray) => {
    const monthlyTotals = Array.from({ length: 12 }, () => 0);
    dataArray.forEach(item => {
      if (item.CA !== null) {
        monthlyTotals[item.mois - 1] += parseFloat(item.CA);
      }
    });
    return monthlyTotals;
  };

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  let currentYearData = [];
  let previousYearData = [];

  // Filtrer les données selon le client ou la liste de clients
  if (client) {
    if (client.includes('_PARENT')) {
      const allenfants = clientsData.filter(c => c.codeClient === client && c.codeAgence === agence);
      const all = [];
      allenfants.forEach(client => {
        if (client.enfants && client.enfants.length > 0) {
          const filteredData = camData.filter(item =>
            client.enfants.some(enfant =>
              item.codeClient === enfant.enfant && item.codeAgence === enfant.agence
            )
          );
          all.push(...filteredData);
        }
      });
      currentYearData = all.filter(item => item.annee === currentYear);
      previousYearData = all.filter(item => item.annee === previousYear);
    } else {
      currentYearData = camData.filter(item => item.codeClient === client && item.codeAgence === agence && item.annee === currentYear);
      previousYearData = camData.filter(item => item.codeClient === client && item.codeAgence === agence && item.annee === previousYear);
    }
  } else if (clientList) {
    const all = [];
    clientList.forEach(client => {
      const filteredData = camData.filter(item =>
        item.codeClient === client.split(' / ')[0] && item.codeAgence === client.split(' / ')[2]
      );
      all.push(...filteredData);
    });
    currentYearData = all.filter(item => item.annee === currentYear);
    previousYearData = all.filter(item => item.annee === previousYear);
  }else{


    if(userInfo.user.role !== 'Administrateur'){
      if((userInfo.user.role).includes('Assistant')){
          currentYearData = camData.filter(item => item.annee === currentYear && item.commercial === ((userInfo.user.role).split(' '))[1]);
          previousYearData = camData.filter(item => item.annee === previousYear && item.commercial === ((userInfo.user.role).split(' '))[1]);
      }else{
        currentYearData = camData.filter(item => item.annee === currentYear && item.commercial === userInfo.user.idUser);
        previousYearData = camData.filter(item => item.annee === previousYear && item.commercial === userInfo.user.idUser);
      }
    }else{
      currentYearData = camData.filter(item => item.annee === currentYear);
      previousYearData = camData.filter(item => item.annee === previousYear);
      
    }
    
  }

  const startMonth = new Date(dateDu).getMonth() + 1;
  const endMonth = new Date(dateAu).getMonth() + 1;

  if (new Date(dateDu).getFullYear() === new Date(dateAu).getFullYear()) {
    currentYearData = currentYearData.filter(item => item.mois >= startMonth && item.mois <= endMonth);
    previousYearData = previousYearData.filter(item => item.mois >= startMonth && item.mois <= endMonth);
  }

  let currentYearCA = calculerSommeParMois(currentYearData);
  let previousYearCA = calculerSommeParMois(previousYearData);

  if (new Date(dateDu).getFullYear() !== new Date(dateAu).getFullYear()) {
    currentYearCA = currentYearCA.map((value, index) => (index + 1 > endMonth ? 0 : value));
  }

  const chartData = {
    labels: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    datasets: [
      {
        label: 'CA de l\'année N',
        data: currentYearCA,
        borderColor: 'rgb(148, 206, 247)',
        backgroundColor: 'rgb(148, 206, 247)',
      },
      {
        label: 'CA de l\'année N-1',
        data: previousYearCA,
        borderColor: 'rgb(241, 110, 110)',
        backgroundColor: 'rgb(241, 110, 110)',
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    plugins: {
      tooltip: {
        mode: 'index'
      }
    },
    legend: {
      display: true,
      position: 'top'
    }
  };

  return (
    <div className='w-full h-[100%] border border-marineLogo rounded-lg bg-blanc'>
      <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo flex flex-col'>
        <span>Mon chiffre d’affaires</span>
      </div>
      <div style={{ width: '100%', height: '80%' }} className='font-bahn chart-container'>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
}

export default GraphCAM;
