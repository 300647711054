import React, {useState} from 'react';
import axios from 'axios';
import logo from '../../assets/HAUTIER-logo.png';
import Title from '../Containers/Title';
import { useLocation, useNavigate } from 'react-router-dom';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

export default function ForgotPass({url}) {

    const query = useQuery();
    const navigate = useNavigate();
    const [password, setpassword] = useState('');
    const [rePassword, setrePassword] = useState('');

    const onChangePassword = (e) => {
        const password = e.target.value;
        setpassword(password);
    };
    const onChangeRePassword = (e) => {
        const rePassword = e.target.value;
        setrePassword(rePassword);
    };


    const handleForgotPass = () => {

        if (password !== rePassword) {
            alert('Les mots de passe ne correspondent pas');
            return;
          }

        const loginData = {
          "email": query.get('email'),
          "token": query.get('token'),
          "password": password
        }
        
        axios.post(url+"auth/forgotPass", loginData)
        
            .then(res => {           
             
              alert("Votre mot de passe à bien été modifié : ")
              
            })
            .then(res => {             
                navigate("/login");
          })
            .catch(error=>{
                alert("Problème lors de réinitialisation du mot de passe")
            })
    };
    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            
            <div>
                <img className="w-[20%]" src={logo} />
            </div>
            <div className="w-full p-6 m-auto bg-white -mt-2  lg:max-w-xl">
                <Title title="Reinitialisation du MDP"/>
                <form  className="mt-6" >
                    <div className="mb-2">
                        <label
                            className="block text-sm font-semibold text-grey"
                        >
                            Nouveau mot de passe
                        </label>
                        <input
                            type="password"
                            name="password"
                            className="block w-full px-4 py-2 mt-2 text-marineLogo bg-white border rounded-md focus:border-marineLogo focus:ring-bleu200 focus:outline-none focus:ring focus:ring-opacity-40"
                            value={password}
                            onChange={onChangePassword}
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            className="block text-sm font-semibold text-grey"
                        >
                            Confirmation du nouveau mot de passe
                        </label>
                        <input
                            type="password"
                            name="password"
                            className="block w-full px-4 py-2 mt-2 text-marineLogo bg-white border rounded-md focus:border-marineLogo focus:ring-bleu200 focus:outline-none focus:ring focus:ring-opacity-40"
                            value={rePassword}
                            onChange={onChangeRePassword}
                        />
                    </div>
                    <div className="mt-6">
                        <button 
                            type="button" 
                            className="w-full px-4 py-2 tracking-wide text-blanc transition-colors duration-200 transform bg-vertLogo rounded-md hover:border hover:bg-blanc hover:text-vertLogo hover:border-vertLogo focus:outline-none focus:bg-blanc focus:text-vertLogo"
                            onClick={handleForgotPass}
                        >
                            Enregistrer
                        </button>
                    </div>
                </form>
            </div>

        </div>
    );
}