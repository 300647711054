import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import bande from "../../assets/logo_bande.png";
import Filtre from './Filtres';
import GenPDF from '../Containers/GeneratePDF';
import ExportExcel from './exportEcxel';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Title({ title, btn, handleExportExcel, dataDoc, reporting,  villeCharg, villeLivraison, onSubmitFilter, client, agence, commercial }) {
    


    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date().toLocaleDateString('fr-FR', options);

    const [online, setOnline] = useState(true);
    const [showPopup, setShowPopup] = useState(true);
    const { id, codeAgence } = useParams() || {};

    useEffect(() => {
        
        const checkConnection = async () => {
            try {
                await axios.get('https://api.hautier-transports.fr/crm/');
                setOnline(true); 
                if (sessionStorage.getItem('hidePopup') === 'true') {
                    setShowPopup(false);
                } else {
                    setShowPopup(true);
                }
                if (localStorage.getItem('New_visit')){
                    const storedVisits = JSON.parse(localStorage.getItem('New_visit'));

                    if (storedVisits && storedVisits.length > 0) {
                        Promise.all(storedVisits.map(visit => {
                            return axios.post('https://api.hautier-transports.fr/crm/visit/createUpd', {
                                formData: visit.formData, 
                                etapes: visit.etapes,
                                materials: visit.materials, 
                                deps: visit.deps,
                                url: visit.url,
                                docu: visit.docu,
                                visitDate: visit.visitDate,
                            }, {
                                headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Content-type": "Application/json",
                                    'Authorization': 'Bearer ' + userInfo.token
                                }
                            }).then(function (response) {
                                console.log('Réponse du serveur:', response.data);
                                alert('La visite réalisée a bien été enregistrée sur le serveur.');
                                return Promise.resolve();
                            }).catch(function (error) {
                                console.error('Erreur lors de la soumission du formulaire:', error);
                                return Promise.reject(error);
                            });
                        })).then(() => {
                            localStorage.removeItem('New_visit');
                        }).catch(error => {
                            console.error('Une erreur s\'est produite lors de l\'envoi des visites:', error);
                        });
                    } else {
                        console.log('Aucune visite à envoyer.');
                    }

                }
                if (localStorage.getItem('New_contact')){
                    const storedContacts = JSON.parse(localStorage.getItem('New_contact'));

                    if (storedContacts && storedContacts.length > 0) {
                        Promise.all(storedContacts.map(contact => {
                            return axios.post("https://api.hautier-transports.fr/crm/client/newContact", {
                                codeClient: contact.codeClient,
                                codeAgence: contact.codeAgence,
                                nom: contact.nom,
                                prenom: contact.prenom,
                                mailContact: contact.mailContact,
                                telContact: contact.telContact,
                                fonction: contact.fonction,
                            }, {
                                headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Content-type": "Application/json",
                                    'Authorization': 'Bearer ' + userInfo.token
                                }
                            }).then(function (response) {
                                console.log('Réponse du serveur:', response.data);
                                alert('Le contact à bien été enregistrée sur le serveur.');
                                return Promise.resolve();
                            }).catch(function (error) {
                                console.error('Erreur lors de la soumission du formulaire:', error);
                                return Promise.reject(error);
                            });
                        })).then(() => {
                            localStorage.removeItem('New_contact');
                        }).catch(error => {
                            console.error('Une erreur s\'est produite lors de l\'envoi des contacts:', error);
                        });
                    } else {
                        console.log('Aucun contact à envoyer.');
                    }
                    
                    
                    
                }
                if (localStorage.getItem('New_Transporteur')){
                    const storedContacts = JSON.parse(localStorage.getItem('New_Transporteur'));

                    if (storedContacts && storedContacts.length > 0) {
                        Promise.all(storedContacts.map(transporteur => {
                            return axios.post('https://api.hautier-transports.fr/crm/client/addTransporteur/', {
                                transporteur: transporteur.transporteur,
                                new: transporteur.new,
                                codeClient: transporteur.client,
                                codeAgence: transporteur.agence
                            }, {
                                headers: {
                                    "Access-Control-Allow-Origin": "*",
                                    "Content-type": "Application/json",
                                    'Authorization': 'Bearer ' + userInfo.token
                                }
                            }).then(function (response) {
                                console.log('Réponse du serveur:', response.data);
                                alert('Le transporteur à bien été enregistrée sur le serveur.');
                                return Promise.resolve();
                            }).catch(function (error) {
                                console.error('Erreur lors de la soumission du formulaire:', error);
                                return Promise.reject(error);
                            });
                        })).then(() => {
                            localStorage.removeItem('New_Transporteur');
                        }).catch(error => {
                            console.error('Une erreur s\'est produite lors de l\'envoi des transporteurs:', error);
                        });
                    } else {
                        console.log('Aucun transporteur à envoyer.');
                    }
                    
                    
                    
                }
            } catch (error) {
                setOnline(false); 
                setShowPopup(true);
                sessionStorage.setItem('hidePopup', 'false'); 
            }
        };

        checkConnection(); 

        const intervalId = setInterval(checkConnection, 5000); 

        return () => {
            clearInterval(intervalId); 
        };
    }, []);

    const handleClosePopup = () => {
        setShowPopup(false); 
        sessionStorage.setItem('hidePopup', 'true'); 
    };

    return (
        <div className="flex flex-column w-full h-full pr-8">
           
            <div className='w-[55%] h-full]'>
            {showPopup  && ( 
                <div style={{ backgroundColor: online ? 'green' : 'red', color: 'white', padding: '10px', textAlign: 'center', position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 9999 }}>
                    {online ? 'Vous êtes passé  en ligne.' : 'Vous êtes passé hors ligne.'}
                    <button onClick={handleClosePopup} style={{ marginLeft: '10px', backgroundColor: 'transparent', border: 'none', color: 'white', cursor: 'pointer' }}>X</button>
                </div>
            )}
                <div className="flex justify-center items-end w-full h-[80%]">
                    <h1 className="text-3xl text-marineLogo uppercase italic">{title}</h1>
                </div>
                <div className='flex w-full'>
                    { btn ? ( 
                    <div className=' w-[30%] h-[80%] ml-2'>
                        <Link to={"/newVisite"}>
                            <button className='bg-vertLogo w-full flex items-center rounded text-blanc justify-center group hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" className="w-8 h-8 group-hover:stroke-vertLogo">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                Nouvelle visite
                            </button> 
                        </Link>
                    </div> 
                    ) : null }
                    { reporting ? ( 
                    <div className=' w-[30%] h-[80%] ml-2'>
                        <Link to={"/reporting"}>
                            <button className='bg-vertLogo w-full flex items-center rounded text-blanc justify-center group hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo'>
                                
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" className="w-8 h-8 group-hover:stroke-vertLogo">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
                                </svg>

                                Mon suivi de prospection
                            </button> 
                        </Link>
                    </div> 
                    ) : null }
                    { handleExportExcel ? (
                        <div className=' w-[30%] h-[80%] ml-2'>
                            <ExportExcel data={handleExportExcel} name={title} />
                        </div> 
                    ) : null }
                    { dataDoc ? (
                        
                        <div className=' w-[30%] h-[80%] ml-2'>
                            <GenPDF data={dataDoc} villeCharg={villeCharg} villeLivraison={villeLivraison} codeClient={id} codeAgence={codeAgence} commercial={commercial} />
                        </div> 
                        
                    ) : null }
                    { title === 'Fiche client' ? (
                        <div className=' w-[30%] h-[80%] ml-2'>
                            <Link to={"/transporteurs/"+ client + "/" + agence}>
                                <button onClick={handleExportExcel} className='bg-vertLogo w-full flex items-center rounded text-blanc justify-center group hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo py-1'>
                                <svg className="group-hover:stroke-vertLogo w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                                </svg>

                                    Transporteurs
                                </button> 
                            </Link>
                    </div> 
                        
                    ) : null }
                </div>
            </div>
            <div className='w-[45%] h-full] flex max-lg:hidden'>
                
                { title === "Accueil" ? (
                <>
                    <div className='h-full w-[60%]'>
                        <img className="h-40" src={bande} />
                    </div>
                    <div className='h-full w-[40%]'>
                        <div className='absolute left-[65%] top-[8%] bg-blanc border rounded-lg border-marineLogo text-marineLogo p-[4px]'><span className=''>{date}</span></div>
                    </div>
                </>
                ) : title === 'Mon Agenda' || title === 'Mon Espace' || title === 'Emission de CO²' || title === 'Connexion' || title === 'Mot de passe oublié ?' || title === 'Reinitialisation du MDP' || title === 'Inscription' || title === 'Fiche client' || title === 'Fiche prospect' || title === 'Formulaire de nouvelle visite' || title === "Avec qui travaillez-vous?" || title === "Mon Equipe" || title === 'Modifier une visite' ? (
                    null 
                ) : (
                <>
                    <div className='h-full w-[60%]'><img className="h-40" src={bande} /></div>
                    <div className='h-full w-[40%] flex justify-center'>
                        <span className='text-2xl text-marineLogo uppercase'>RECHERCHE</span>
                    </div>
                    <Filtre onSubmitFilter={onSubmitFilter} title={title}/>
                    
                </>
                ) }
            </div>
            
        </div>
    );
} 