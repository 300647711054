import React, { useState, useEffect } from 'react';


function TableComponent({ villeData, nom }) {
    const villesParAnnee = {};
    villeData.forEach(ville => {
      const key = `${ville.villeDep}-${ville.cpDep}`;
      if (!villesParAnnee[key]) {
        villesParAnnee[key] = [];
      }
      villesParAnnee[key].push(ville);
    });

    return (
        <div className='h-[100%] w-[100%]  overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
            <table className="table-fixed w-full">
                <thead>
                <tr className="text-marineLogo">
                    <th className="px-2 py-2">Ville - Code Postal</th>
                    <th className="px-2 py-2">Année</th>
                    <th className="px-2 py-2">Nombre d'Occurrences</th>
                </tr>
                </thead>
                <tbody>
                    {Object.keys(villesParAnnee).map((villeCp, index) => (
                        villesParAnnee[villeCp].map((entry, subIndex) => (
                        <tr key={`${index}-${subIndex}`} className={subIndex === 0 ? "bg-white" : "bg-gray-100"}>
                            <td className="px-2 py-2 text-center">{subIndex === 0 ? villeCp : ' '}</td>
                            <td className="px-2 py-2 text-center">{entry.annee}</td>
                            <td className="px-2 py-2 text-center">{entry.nombreOccurrences}</td>
                        </tr>
                        ))
                        .concat(<tr key={`border-${index}`} className="border-b border-marineLogo"></tr>)
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TableComponent;
