import React, { useEffect } from 'react';
import {Routes, Route, Navigate, useNavigate, useMatch} from "react-router-dom";
import './App.css';
import Login from './components/LoginRegister/Login';
import Accueil from './components/Accueil/Accueil';
import Agenda from './components/Agenda/agenda'
import Register from './components/LoginRegister/Register'
import Visites from './components/Visites/Visites'
import Clients from './components/Clients/Clients'
import Equipe from './components/Equipes/Equipes'
import Ca from './components/Cas/Cas'
import Kpi from './components/Kpis/Kpis'
import TopClients from './components/TopClients/TopClients'
import Profil from './components/Profils/Profils'
import ProfilClient from './components/Profils/Client'
import Fiche from './components/Profils/Profils'
import Conso from './components/Conso/Conso'
import Affect from './components/Affect/AffectClients'
import NewVisite from './components/Visites/NewVisite'
import EnableProspect from './components/EnableProspect/EnableProspect'
import Transporteurs from './components/Transporteurs/Transporteurs';
import ForgotPass from './components/LoginRegister/ForgotPass'
import MdpForget from './components/LoginRegister/MdpForget'
import Calculatrice from './components/Calculatrice/Calculatrice'
import Reporting from './components/Reporting/Reporting'
import { jwtDecode as decode } from 'jwt-decode';



function App() {
  const baseUrl = "https://api.hautier-transports.fr/crm/";
  const urlTrimble = "https://api.hautier-transports.fr/trimble/";

  //const baseUrl = "http://localhost:3000/crm/";
  //const urlTrimble = "http://localhost:3000/trimble/";
  const navigate = useNavigate();
  const isLoginPage = useMatch('/login');

  useEffect(() => {
    if (isLoginPage) {

      const checkToken = () => {
        const storedTokenString = localStorage.getItem('token');
        console.log(storedTokenString)

        if (storedTokenString) {
          try {
              const storedToken = JSON.parse(storedTokenString);
              const token = storedToken.token;
              
              const decodedToken = decode(token);
              const tokenExp = decodedToken.exp;
              const now = Math.floor(Date.now() / 1000);
      
              if (now > tokenExp) {
                  console.log("Le token est expiré.");
              } else {
                  console.log("Le token est encore valide.");
                  const userId = storedToken.user.idUser;
                  navigate(`/accueil/${userId}`);
              }
          } catch (error) {
              console.error('Erreur lors du décodage du token :', error);
          }
        }
      };

      checkToken();
    }
  }, [isLoginPage, navigate]);

  return (
    
        <Routes>
          <Route exact path='accueil/:id' element={<Accueil url={baseUrl} urlTrimble={urlTrimble} />}/>
          <Route exact path="login" element={<Login url={baseUrl} />}/>
          <Route exact path='agenda' element={<Agenda  url={baseUrl} />}/>
          <Route exact path='inscription' element={<Register  url={baseUrl} />}/>
          <Route exact path='client/:id' element={<Clients url={baseUrl} />}/>
          <Route exact path='visite/:id' element={<Visites url={baseUrl} />}/>
          <Route exact path='equipe' element={<Equipe url={baseUrl}/>}/>
          <Route exact path='ca/:id' element={<Ca url={baseUrl} />}/>
          <Route exact path='kpi' element={<Kpi url={baseUrl} />}/>
          <Route exact path='TopClients' element={<TopClients url={baseUrl} />}/>
          <Route exact path='profil/:id' element={<Profil url={baseUrl}/>}/>
          <Route exact path='profil/client/:id/:codeAgence' element={<ProfilClient url={baseUrl}/>}/>
          <Route exact path='fiche/:code' element={<Fiche url={baseUrl} />}/>
          <Route exact path='conso' element={<Conso url={baseUrl} />}/>
          <Route exact path='affect' element={<Affect url={baseUrl} />}/>
          <Route exact path='newVisite' element={<NewVisite url={baseUrl} />}/>
          <Route exact path='forgotPass' element={<ForgotPass url={baseUrl} />}/>
          <Route exact path='mdpForget' element={<MdpForget url={baseUrl} />}/>
          <Route exact path='calculatrice' element={<Calculatrice url={baseUrl} />}/>
          <Route exact path='newVisite/:id' element={<NewVisite url={baseUrl} />}/>
          <Route exact path='reporting' element={<Reporting url={baseUrl} />}/>
          <Route exact path='enableProspect' element={<EnableProspect url={baseUrl} />}/>
          <Route exact path='transporteurs/:id/:codeAgence' element={<Transporteurs url={baseUrl} />}/>
          <Route path='*' element={<Navigate to='login' />} />
        </Routes>
  );
}

export default App;
