import React, { useState, useEffect } from 'react';
import axios from "axios";
import db from '../../../Db/db';

function FormAddTransporteur({url, token, onSubmitSuccess, client, agence}) {
    
    const [transporteurs, setTransporteurs] = useState([]);
    const [online, setOnline] = useState(true);

    const resetForm = () => {
        document.getElementById('new').value = '';
 
    };

    useEffect(() => {
        async function fetchData() {
            const data = await db.tansporteur.toArray();
            console.log(data)
            setTransporteurs(data);
        }

        fetchData();
    }, []);


    const checkConnection = async () => {
        try {
            await axios.get('https://api.hautier-transports.fr/crm/');
            setOnline(true); 
            
        } catch (error) {
            setOnline(false);  
        }
    };

    let offlineFormData = JSON.parse(localStorage.getItem('New_Transporteur')) || [];
    const saveOfflineData = (data, callback) => {
        offlineFormData.push(data);
        localStorage.setItem('New_Transporteur', JSON.stringify(offlineFormData));
        callback();
    };
   

    const submit = (e) => {
        checkConnection();

        e.preventDefault();
        console.log(e.target);

        if (online) {
            axios.post(url + 'client/addTransporteur/', {
                transporteur: e.target.transporteur.value,
                new: e.target.new.value,
                codeClient: client,
                codeAgence: agence
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    'Authorization': 'Bearer ' + token
                }
            }).then(() => {
                onSubmitSuccess();
                resetForm();
                alert('Le transporteur a bien été enregistré');
            }).catch(function (error) {
                console.log(error);
            });
        } else {
            let dataToSend = {
                transporteur: e.target.transporteur.value,
                new: e.target.new.value,
                codeClient: client,
                codeAgence: agence
            };
            saveOfflineData(dataToSend, () => {
                alert('Le transporteur a bien été enregistré');
            });
            db.tansporteur.add({
                name : e.target.new.value ? e.target.new.value : e.target.transporteur.value
            })
    
            
            onSubmitSuccess();
            resetForm();
        }
        //db.transporteurCom.add({"codeClient":client,"codeAgence":agence,"name":e.target.new.value ? e.target.new.value : e.target.transporteur.selectedOptions[0].text})
    };

   
    
    return(
        <div className="w-full flex h-[90%]">
            <div className="bg-blanc w-full max-lg:w-full p-4 rounded-lg shadow-lg border border-marineLogo min-h-[20%] max-h-[80%] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100">
                <h2 className="text-[100%] font-semibold text-marineLogo mb-4">Recherche d'un transporteur</h2>
                <form onSubmit={submit} className='flex flex-col' id='monFormulaire'>
                    <div>
                        <div className='flex'>
                            <div className="mb-4 mr-[3%] w-[90%]">
                                <label htmlFor="transporteur" className="block text-sm font-medium text-grey">Nom du Transporteur :</label>
                                <select
                                    id="transporteur"
                                    className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                    placeholder="transporteur"
                                >
                                    <option value=""></option>
                                    {transporteurs.map((t, index) => (
                                        <option key={index} value={t.id}>{t.name}</option>
                                    ))}
                                </select>
                            </div>
                            
                        </div>
                        <div className='flex'>
                            <div className="mb-4 mr-[3%] w-[90%]">
                                <label htmlFor="new" className="block text-sm font-medium text-grey">Nom du Transporteur (Introuver) :</label>
                                <input type="text" name="new" id="new" className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none" />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-end">
                    <button
                        type="submit"
                        className=" mr-2 px-4 py-1 bg-vertLogo w-[30%] flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                        onClick={checkConnection()}
                    >
                        Ajouter
                    </button>
                </div>
                </form>
            </div>
        </div>
    )
}
export default FormAddTransporteur;