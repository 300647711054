import React, { useState, useEffect } from 'react';
import axios from "axios";

function Chats({url, id, token}) {
    const [chats, setChat] = useState([]);
    const [message, setMessage] = useState('');
    useEffect(() => {
        setChats();
    }, []);
    const senChat = (e) =>{
        e.preventDefault();
        axios.post(url+'chats/', {
           idUser: id,
           message: e.target.message.value,
        },{
           headers: {
               "Access-Control-Allow-Origin": "*",
               "Content-type": "Application/json",
               'Authorization': 'Bearer ' + token
           }
        }).then(res => {         
            setChats();
            setMessage('');
            
            
        }).catch(error=>{
           console.log(error);
           
       });
       }
       function setChats(){
        axios.get(url+'chats/', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {        
            setChat(res.data);
            
        }).catch(error=>{
            console.log(error);
            
        });
    }
    return (
        <div className='bg-blanc font-bahn border border-marineLogo rounded-lg flex flex-col h-[100%] w-[100%]  '>
            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo flex flex-col'>
                <span>La messagerie </span>
            </div>
            <div className='pl-2'>
                <form onSubmit={senChat}>
                    <input type="text" placeholder='Message' id='message' name='message' className='w-[90%]' value={message} onChange={(e) => setMessage(e.target.value)} />
                    <button type='submit'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>

                    </button>
                </form>
            </div>
            <div className='mt-[2%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
                <ul className='overflow-x-auto overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full max-w-full'>
                    {chats.map(chat => (
                        <>
                        <li className='text-[70%]'>{new Date(chat.dateEnvoi).toLocaleString()} </li>
                        <li key={chat.id} className='pl-4'><span className='italic text-marineLogo'>{chat.name} {chat.firstName}</span>  : {chat.message} </li>
                        </>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Chats;