import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title as ChartTitle,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend
);

const GraphClients = ({ chartData, chartOptions }) => {
    return (
        <div className='w-full h-full'>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default GraphClients;
