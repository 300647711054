import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import customIcon from '../../assets/markerVert.png';

const customMarkerIcon = new L.Icon({
  iconUrl: customIcon,
  iconSize: [22, 22],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
});

function MapComponent({ villeData, nom }) {
  
  const villesParAnnee = {};
  villeData.forEach(ville => {
    const key = `${ville.villeDep}-${ville.cpDep}`;
    if (!villesParAnnee[key]) {
      villesParAnnee[key] = [];
    }
    villesParAnnee[key].push(ville);
  });
  console.log(villesParAnnee)
  useEffect(() => {
  }, [villeData]);
  

  return (
    <div id="map" style={{ width: '100%', height: '100%' }} className='font-bahn z-10'>
      <MapContainer center={[46.58298, 2.00118]} zoom={5} className='h-full w-[90%]'>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {Object.keys(villesParAnnee).map(key => {
          const villes = villesParAnnee[key];
          const ville = villes[0]; 
          return (
            <Marker
              key={`${ville.villeDep}-${ville.cpDep}-${nom}`}
              position={[ville.vildlat, ville.vildlong]}
              icon={customMarkerIcon}
            >
              <Popup>
                Ville: {ville.villeDep} {ville.cpDep}<br />
                {villes.map(v => (
                  <div key={v.annee}>
                    Nombre d'OT {v.annee} : {v.nombreOccurrences}<br />
                  </div>
                ))}
              </Popup>
            </Marker>
          );
        })}
      </MapContainer>
    </div>
  );
}

export default MapComponent;
