import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import db from '../../Db/db';

function VisitePro() {
    
    const [visitePro, setvisitePro] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const data = await db.visitePrevue.toArray();
            setvisitePro(data);
        }

        fetchData();
    }, []);

    return (
        <div className='bg-blanc font-bahn border border-marineLogo rounded-lg flex flex-col h-[90%] w-[100%]  overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
                            
            <div className='flex justify-center border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                </svg>
                <p>Mes visites programmées</p>
            </div>
            <div className='flex-shrink max-h-[90%] w-full mt-[5%] font-bahn overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                {visitePro.map(client => (
                    <div className="w-full bg-white border-b border-bleu m-[1%]">
                        <div className="flex flex-row w-full h-full">
                            <Link to={"/profil/client/" + client.codeClient + "/" + client.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                                <div className="h-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-8 h-full">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </div>
                                <div>
                                    <ul className="text-xs w-full  text-marineLogo">
                                        <li className="w-full rounded-sm">
                                            <span className=" w-full font-bahn">{client.nomClient}</span>
                                        </li>
                                        <li className=" w-full rounded-sm">
                                            <span className=" w-full font-bahn">{client.codeClient}</span>
                                        </li>
                                    </ul>
                                </div>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default VisitePro;
