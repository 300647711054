import React, { useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import db from '../../Db/db';

function Etapes() {
    
    const [etapesData, setetapesData] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const data = await db.etapes.toArray();
            setetapesData(data);
        }

        fetchData();
    }, []);

    return (
        <div className='bg-blanc font-bahn border border-marineLogo rounded-lg flex flex-col h-[90%] w-[100%]  overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                <span>Mes futures étapes</span>
            </div>
            <div className='flex items-center justify-center'>
            {etapesData.map(e => (
                <div className="w-full bg-white border-b border-bleu">
                    <div className="flex flex-row w-full h-full">
                    <Link to={"/profil/client/" + e.codeClient + "/" + e.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                           
                            <div>
                                <ul className="text-xs w-full text-marineLogo">
                                    <li className="w-full rounded-sm">
                                        <span className=" w-full font-bahn">Date : {new Date(e.date).toLocaleDateString('fr-FR')} Client : {e.nomClient} Sujet : {e.sujet}</span>
                                    </li>
                                </ul>
                            </div>
                        </Link>
                    </div>
                </div>
            ))}
            </div>
           
        </div>
    );
}

export default Etapes;

/**
 * <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo flex flex-col'>
                                    <span>Mes futures étapes</span>
                                </div>
                                <div className=''>
                                    <ul className=' overflow-x-auto overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full max-w-full'>

                                        {etapes.map(etape => (
                                            <li>{dateFormat(etape.date, 'dd/mm/yyyy')} | {etape.nomClient} : {etape.sujet}</li>
                                        ))}

                                    </ul>
                                </div>
 */