import React, {useState} from 'react';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/HAUTIER-logo.png';
import Title from '../Containers/Title';


export default function MdpForget({url}) {

    const [email, setemail] = useState('');
    const navigate = useNavigate();

    const onChangeEmail = (e) => {
        const email = e.target.value;
        setemail(email);
    };


    const handleMail = () => {

        const loginData = {
          "email": email
        }
        
        axios.post(url+"auth/forgotPassMail", loginData)
        
            .then(res => {           
             
              alert("Un mail vient de vous être envoyé ! ")
              
            })
            .then(res => {             
                navigate("/login");
          })
            .catch(error=>{
                alert("Problème lors de l'envoie du mail")
            })
    };
    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            
            <div>
                <img className="w-[20%]" src={logo} />
            </div>
            <div className="w-full p-6 m-auto bg-white -mt-2  lg:max-w-xl">
                <Title title="Mot de passe oublié ?"/>
                <form  className="mt-6" >
                    <div className="mb-2">
                        <label
                            className="block text-sm font-semibold text-grey"
                        >
                            Adresse email
                        </label>
                        <input
                            type="text"
                            name="email"
                            className="block w-full px-4 py-2 mt-2 text-marineLogo bg-white border rounded-md focus:border-marineLogo focus:ring-bleu200 focus:outline-none focus:ring focus:ring-opacity-40"
                            value={email}
                            onChange={onChangeEmail}
                        />
                    </div>
                    <div className="mt-6">
                        <button 
                            type="button" 
                            className="w-full px-4 py-2 tracking-wide text-blanc transition-colors duration-200 transform bg-vertLogo rounded-md hover:border hover:bg-blanc hover:text-vertLogo hover:border-vertLogo focus:outline-none focus:bg-blanc focus:text-vertLogo"
                            onClick={handleMail}
                        >
                            Demander un mail de réinitialisation
                        </button>
                    </div>
                </form>
            </div>

        </div>
    );
}