import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import db from '../../Db/db';
import axios from "axios";
import { useParams } from "react-router-dom";

export default function NewVisite() {
    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);
    const [selectedOption, setSelectedOption] = useState("");
    const [clientsData, setClientsData] = useState([])
    const [clientSelect, setClientsSelect] = useState([])
    const [agenceData, setAgenceData] = useState([])
    const [activiteData, setActiviteData] = useState([])
    const [cityChargData, setCityChargData] = useState([]);
    const [cityLivrData, setCityLivrData] = useState([]);
    const [contact, setContact] = useState([]);
    const [clientContact, setClientContact] = useState([]);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");


    const [cityCharg, setCityCharg] = useState([]);
    const [cityLivr, setCityLivr] = useState([]);
    const [teams, setTeam] = useState(null);
    const [selectedContact, setSelectedContact] = useState(null);

    const { id } = useParams();

    const [formData, setFormData] = useState({
        idCom: userInfo.user.idUser,
        nom: null,
        prenom: null,
        fonction: null,
        email: null,
        num: null,
        topics: null,
        type: null,
        secteur: null,
        site: null,
        ca: null,
        budget: null,
        adressEnt: null,
        villeEnt: null,
        cpEnt: null,
        paysEnt: null,
        emailEnt: null,
        numEnt: null,
        codeClient: null,
        nextVisitDate: null,
        idContact: null,
        nomClient: null,
        idVisite: null
    });

    useEffect(() => {
        if (message || error) {
            const timer = setTimeout(() => {
                setMessage("");
                setError("");
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [message, error]);

    useEffect(() => {
        async function fetchData() {
            setLoading(true); 
            const data1 = await db.clients.toArray();
            setClientsData(data1);
            const data8 = await db.contacts.toArray();
            setContact(data8);
            const data2 = await db.agences.toArray();
            setAgenceData(data2);
            const data3 = await db.activite.toArray();
            setActiviteData(data3);
            const data7 = await db.team.toArray();
            setTeam(data7.filter(team => team.idUser == userInfo.user.idUser));

            const data4 = await db.cityCharg.toArray();
            const data5 = await db.cityLivr.toArray();
            setCityChargData(data4);
            setCityLivrData(data5);

            if (id) {
                console.log(id)
                const data = await db.visites.toArray();
                const visit = data.find(visit =>visit.id == id );
                console.log(data.find(visit => visit.id == id))
                if (visit) {
                    

                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        idCom: visit.idUser,
                        nom: visit.nom,
                        prenom: visit.prenom,
                        fonction: visit.fonction,
                        email: visit.mailContact,
                        num: visit.telContact,
                        topics: visit.action,
                        type: visit.typeClient,
                        secteur: visit.activite,
                        site: visit.codeAgence,
                        ca: visit.caAnnuel,
                        budget: visit.budgetTL,
                        adressEnt: visit.adressClient,
                        villeEnt: visit.villeClient,
                        cpEnt: visit.cpClient,
                        paysEnt: visit.paysClient,
                        emailEnt: visit.emailClient,
                        numEnt: visit.telClient,
                        codeClient: visit.codeClient,
                        nextVisitDate: visit.nextVisitDate,
                        idContact: visit.idContact,
                        nomClient: visit.nomClient,
                        idVisite: id
                    }));
    
                    setSelectedOption(visit.typeClient); 
                    console.log(visit)
                }
            }
            setLoading(false);
        }
    
        fetchData();
        
    }, [id, userInfo.user.idUser]);

   // let clientsData = clientData
    /*if(userInfo.user.role !== 'Administrateur'){
        clientsData = clientData.filter(client => client.commercial == userInfo.user.idUser && (selectedOption ? client.typeClient === selectedOption : true))
    }else{
        clientsData = clientData.filter(client => (selectedOption ? client.typeClient === selectedOption : true))
    }*/

    
    



    const handleOptionChange = (event) => {
        //clientsData = clientData.filter(cli => cli.typeClient === event.target.value)
        setClientsSelect(clientsData.filter(cli => cli.typeClient === event.target.value))
        setSelectedOption(event.target.value);
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

  
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
        console.log(formData)
    
        if (name === 'client') {
            const [nomClient, codeAgence, codeClient] = value.split(' / ');
    
            setClientContact(contact.filter(cont => cont.codeClient === codeClient && cont.codeAgence === codeAgence));
    
            if (codeClient.includes('_PARENT')) {
                const allenfants = clientsData.filter(client => client.codeClient === codeClient && client.codeAgence === codeAgence);
                const all = [];
                const all2 = [];
                allenfants.forEach(client => {
                    if (client.enfants && client.enfants.length > 0) {
                        const filteredData = cityChargData.filter(item =>
                            client.enfants.some(enfant =>
                                item.idClient === enfant.enfant && item.codeAgence === enfant.agence
                            )
                        );
                        all.push(...filteredData);
                        const filteredData2 = cityLivrData.filter(item =>
                            client.enfants.some(enfant =>
                                item.idClient === enfant.enfant && item.codeAgence === enfant.agence
                            )
                        );
                        all2.push(...filteredData2);
                    }
                });
                setCityCharg(all);
                setCityLivr(all2);
            } else {
                setCityCharg(cityChargData.filter(client => client.idClient === codeClient && client.codeAgence === codeAgence));
                setCityLivr(cityLivrData.filter(client => client.idClient === codeClient && client.codeAgence === codeAgence));
            }
        }
    
        
    };

    const [visitDateExt, setVisitDateExt] = useState(new Date().toISOString().split('T')[0]);
    const handleChangeDate = (event) => {
        setVisitDateExt(event.target.value);
    };

    const [steps, setSteps] = useState([]);
    const [currentStep, setCurrentStep] = useState({
        text: '',
        date: '',
    });
    const handleAddStep = () => {
        if (currentStep.text !== '' && currentStep.date !== '') {
          setSteps([...steps, currentStep]);
          setCurrentStep({
            text: '',
            date: '',
          });

        }
    };

    const [materials, setMaterials] = useState([]);
    const [currentMaterial, setCurrentMaterial] = useState('');
    const handleAddMaterial = () => {
        if (currentMaterial !== '') {
          setMaterials([...materials, currentMaterial]);
          setCurrentMaterial('');
        }
    };

    const [deps, setDeps] = useState([]);
    const [currentDep, setCurrentDep] = useState({
        pays: '',
        ville: '',
        cp: '',
    });
    const handleAddDep = () => {
        if (currentDep !== '') {
          setDeps([...deps, currentDep]);
          setCurrentDep({
            pays: '',
            ville: '',
            cp: '',
          });
        }
    };
    const [online, setOnline] = useState(true);
    const checkConnection = async () => {
        try {
            await axios.get('https://api.hautier-transports.fr/crm');
            setOnline(true); 
            
        } catch (error) {
            setOnline(false);  
        }
    };
    /*let offlineFormData = JSON.parse(localStorage.getItem('New_visit')) || [];
        
        const saveOfflineData = (data, callback) => {
            offlineFormData.push(data);
            localStorage.setItem('New_visit', JSON.stringify(offlineFormData));
            callback();
        };*/

    
    const handleSubmit = async (event) => {
        checkConnection(); 
        event.preventDefault();
        
        console.log(formData);
        console.log(steps)
        console.log(materials)
        console.log(deps)
        console.log(visitDateExt)
        
        const doc = document.getElementById('docExt');
        const url = doc && doc.files && doc.files.length > 0 ? doc.files[0].name : null;
            
        const docu = doc && doc.files && doc.files.length > 0 ? doc.files[0] : null;
        console.log(docu)

        //if(online){
            
            axios.post('https://api.hautier-transports.fr/crm/visit/createUpd', {
                formData, 
                etapes: steps,
                materials: materials, 
                deps: deps,
                url: url,
                docu: docu,
                visitDate: visitDateExt,
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": 'multipart/form-data',
                    'Authorization': 'Bearer ' + userInfo.token
                }
            }).then(function (response) {
                console.log('Réponse du serveur:', response.data);
                setMessage("La visite a bien été envoyée !");
                setError("");
                //handleCancel();
                alert('La visite a bien été enregistrée');
            }).catch(function (error) {
                console.error('Erreur lors de la soumission du formulaire:', error);
                setError("Échec de l'envoi de la visite. Veuillez réessayer.");
                setMessage("");
            });
       // }else{
           /* let dataToSend;
            dataToSend = {
                formData, 
                etapes: steps,
                materials: materials, 
                deps: deps,
                url: url,
                docu: docu,
                visitDate: visitDateExt,*/
           // }

            /*saveOfflineData(dataToSend, () => {
                alert('la visite a bien été enregistré')
            });
            handleCancel();*/
          //  console.log("pas co")
            
        //}
        try {
            const maxId = await db.visites.orderBy('id').last();
            let newId = ''
            if (id){
                newId = id
            }else{
                newId = maxId ? maxId.id + 1 : 1;
            }
            
            await db.visites.put({
                id: newId,
                name: userInfo.user.name,
                firstName: userInfo.user.firstName,
                idUser: userInfo.user.idUser,
                dateVisite: visitDateExt,
                action: formData.topics,
                urlDoc: url,
                nexvisiteDate: formData.nextVisitDate,
                codeClient: formData.codeClient,
                nomClient: formData.nomClient,
                secteur: formData.secteur,
                codeAgence: formData.site,
                typeClient: formData.type,
                adressClient: formData.adressEnt,
                cpClient: formData.cpEnt,
                villeClient: formData.villeEnt,
                paysClient: formData.paysEnt,
                telClient: formData.numEnt,
                emailClient: formData.emailEnt,
                budgetTL: formData.budget,
                caAnnuel: formData.ca,
                nom: formData.nom,
                prenom: formData.prenom,
                mailContact: formData.email,
                telContact: formData.num,
                fonction: formData.fonction
                    
            });

            await db.clients.put({
                codeClient: formData.codeClient,
                codeAgence: formData.site,
                nomClient: formData.nomClient,
                adressClient: formData.adressEnt,
                cpClient: formData.cpEnt,
                villeClient: formData.villeEnt,
                paysClient: formData.paysEnt,
                telClient: formData.numEnt,
                emailClient: formData.emailEnt,
                budgetTL: formData.budget,
                caAnnuel: formData.ca,
                typeClient: formData.type,
                commercial: userInfo.user.idUser,
                enfant: null
            });
            const maxIdC = await db.contacts.orderBy('id').last();
            const newIdC = maxIdC ? maxIdC.id + 1 : 1;
            await db.contacts.put({
                id: formData.idContact || newIdC,
                codeClient: formData.codeClient,
                codeAgence: formData.site,
                nom: formData.nom,
                prenom: formData.prenom,
                mailContact: formData.email,
                telContact: formData.num,
                tel2Contact: null,
                fonction: formData.fonction,
                dateVisite: null,
                updateDate: null,
                isActive: 1

            });
            const maxIdE = await db.etapes.orderBy('id').last();
            const newIdE = maxIdE ? maxIdE.id + 1 : 1;
            await steps.map((e) => (

                 db.etapes.put({
                    id: newIdE,
                    sujet: e.text,
                    date: e.date,
                    idVisite: newId,
                    nomClient: formData.nomClient,
                    codeClient: formData.codeClient,
                    codeAgence: formData.site

                })
            ));
            /*const depart = await db.etapes.put(id, {
                

            });*/
            
        } catch (error) {
            alert("failed")
        }
        

    };

    const handleNameChange = (event) => {
        const selectedName = event.target.value;
        const contact = clientContact.find(client => `${client.nom} ${client.prenom}` === selectedName);
        if (contact) {
          setSelectedContact(contact);
          
          setFormData((prevFormData) => ({
            ...prevFormData,
            nom: contact.nom,
            prenom: contact.prenom,
            fonction: contact.fonction,
            email: contact.mailContact,
            num: contact.telContact,
            idContact: contact.id
        }));

        } else {
          setSelectedContact(null);
          handleChange(event);
        }
      };

      const handleClientChange = (event) => {
        const selectedClient = event.target.value;
        const [nomClient, codeAgence, codeClient] = selectedClient.split(' / ');
        const client = clientsData.find(client => client.codeClient === codeClient && client.codeAgence === codeAgence);
        console.log(client)
    
        if (client) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                codeClient: client.codeClient,
                nomClient: client.nomClient,
                secteur: client.secteur,
                site: client.codeAgence,
                ca: client.caAnnuel,
                budget: client.budgetTL,
                adressEnt: client.adressClient,
                villeEnt: client.villeClient,
                cpEnt: client.cpClient,
                paysEnt: client.paysClient,
                emailEnt: client.emailClient,
                numEnt: client.telClient,
                client: selectedClient
            }));
    
            handleChange({
                target: {
                    name: 'client',
                    value: selectedClient
                }
            });
        } else {
            console.error("Client not found or invalid selection");
        }
    }

    const handleCancel = () => {
        setFormData({
            idCom: userInfo.user.idUser,
            nom: null,
            prenom: null,
            fonction: null,
            email: null,
            num: null,
            topics: null,
            type: null,
            secteur: null,
            site: null,
            ca: null,
            budget: null,
            adressEnt: null,
            villeEnt: null,
            cpEnt: null,
            paysEnt: null,
            emailEnt: null,
            numEnt: null,
            codeClient: null,
            nextVisitDate: null,
            idContact: null,
            nomClient: null,
            idVisite: null
        });
        setSteps([]);
        setMaterials([]);
        setDeps([]);
        setSelectedOption("")
    };
   

    return (
    <div className='flex flex-row h-full w-full fixed'>
        <Sidebar />
        <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
            <div className="h-[15%] w-full">
                <Title title="Formulaire de nouvelle visite" btn={false} />
            </div>
            {!loading ? (
                
            <div className='flex flex-wrap mt-4 justify-center w-full h-full overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
                <div className="flex justify-center mt-4">
                    {message && (
                        <div className="text-green-500 font-bold">{message}</div>
                    )}
                    {error && (
                        <div className="text-red-500 font-bold">{error}</div>
                    )}
                </div>
                
                <form encType="multipart/form-data" className='flex flex-col w-full h-full' id='monFormulaire' onSubmit={handleSubmit}>
                    <div className='flex justify-between mr-8'>
                        {/**Premier champ cacher le reste du formulaire tant qu'il n'ai pas rempli */}
                        <div className="mb-4 ml-4 flex flex-col w-[30%] h-full">
                            <label htmlFor="type" className="block text-sm font-medium text-roseLogo ">
                                Type de client :
                            </label>
                            <select
                                name="type"
                                id="type"
                                className=" mx-4 border border-roseLogo hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border "
                                required
                                onChange={handleOptionChange}
                                value={selectedOption }
                                
                            >
                                <option value="">---</option>
                                <optgroup label="Via le formulaire">
                                <option value="prospect">Prospect</option>
                                <option value="client-effectif">Client Effectif</option>
                                <option value="parent">Client Parent</option>
                                <option value="nouveau-prospect">Nouveau Prospect</option>
                                </optgroup>
                                <optgroup label="Via un document">
                                <option value="docE">Ajout document (Existant)</option>
                                <option value="docN">Ajout document (Nouveau)</option>
                                </optgroup>
                            </select>
                        </div>
                        {/** pour tous */}
                        {selectedOption   ? (
                            <div className='w-[30%] mb-4 ml-4'>
                                <label htmlFor="visitDate" className="block text-sm font-medium text-roseLogo">
                                    Date de la visite:
                                </label>
                                <input
                                    type="date"
                                    name="visitDate"
                                    id="visitDate"
                                    className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none"
                                    onChange={handleChangeDate}
                                    value={visitDateExt}
                                    required
                                />
                            </div>
                        ) : null}

                        
                    </div>
                    
                    

                    {/** Si champ 1 à été selectionner sur "docN" ou "nouveau-prospect" */}
                    {selectedOption   ? (
                        <fieldset className="mb-4 mx-4">
                            {selectedOption === 'docN' || selectedOption === 'nouveau-prospect' ? (
                            <legend className="text-lg font-semibold mb-2 text-marineLogo">Création d'un nouveau Client / Prospect</legend>
                            ) :<legend className="text-lg font-semibold mb-2 text-marineLogo">Choix du Client / Prospect</legend>}
                            <div className='flex mx-4'>
                            {selectedOption === 'docN' || selectedOption === 'nouveau-prospect' ? (
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="nomClient" className="block text-sm font-medium text-roseLogo">
                                        Nom de l'entreprise :
                                    </label>
                                    <input
                                        type="text"
                                        name="nomClient"
                                        id="nomClient"
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none"
                                        onChange={handleChange}
                                        value={formData.nomClient}
                                        required
                                    />
                                </div>
                                ) :
                                    <div className="mb-4 mr-[3%] w-[30%]">
                                        <label htmlFor="codeClient" className="block text-sm font-medium text-roseLogo">Liste des clients / prospects :</label>
                                        <input
                                            list="clients"
                                            name="codeClient"
                                            id="codeClient"
                                            className="mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none"
                                            onChange={handleClientChange}
                                            value={formData.codeClient}
                                        />
                                        <datalist id="clients">
                                            <option value="">Sélectionnez un client</option>
                                            {clientSelect.map((client, index) => (
                                                <option key={index} value={`${client.nomClient} / ${client.codeAgence} / ${client.codeClient}`}>
                                                    {client.nomClient} / {client.codeAgence} / {client.codeClient}
                                                </option>
                                            ))}
                                        </datalist>
                                    </div>
                                }
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="secteur" className="block text-sm font-medium text-roseLogo">
                                        Secteur d'activité :
                                    </label>
                                    <input
                                        type="text"
                                        name="secteur"
                                        id="secteur"
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none "
                                        onChange={handleChange}
                                        value={formData.secteur || ""}
                                        required
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="secteur" className="block text-sm font-medium text-roseLogo">
                                        Site :
                                    </label>
                                    <select
                                        name="site"
                                        id="site"
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none "
                                        onChange={handleChange}
                                        value={formData.site || ""}
                                        required
                                    >
                                        <option value="">Sélectionnez un site</option>
                                        {agenceData.map((agence, index) => 
                                            <option key={index} value={agence.code}>{agence.code}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className='flex  mx-4'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="ca" className="block text-sm font-medium text-marineLogo">CA annuel :</label>
                                    <input 
                                        type="text" 
                                        name="ca" 
                                        id="ca" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange} 
                                        value={formData.ca || ""}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="budget" className="block text-sm font-medium text-marineLogo">Budget transport/logistique :</label>
                                    <input 
                                        type="text" 
                                        name="budget" 
                                        id="budget" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange} 
                                        value={formData.budget || ""}
                                        
                                    />
                                </div>
                            </div>
                            <div className='flex  mx-4'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="adressEnt" className="block text-sm font-medium text-marineLogo">Adresse :</label>
                                    <input 
                                        type="text" 
                                        name="adressEnt" 
                                        id="adressEnt" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange}  
                                        value={formData.adressEnt || ""}
                                    />
                                </div>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="villeEnt" className="block text-sm font-medium text-marineLogo">Ville :</label>
                                    <input 
                                        type="text" 
                                        name="villeEnt" 
                                        id="villeEnt" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange} 
                                        value={formData.villeEnt || ""}
                                    />
                                </div>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="cpEnt" className="block text-sm font-medium text-marineLogo">Code postal :</label>
                                    <input 
                                        type="text" 
                                        name="cpEnt" 
                                        id="cpEnt" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange} 
                                        value={formData.cpEnt || ""}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="paysEnt" className="block text-sm font-medium text-marineLogo">Pays :</label>
                                    <input 
                                        type="text" 
                                        name="paysEnt" 
                                        id="paysEnt" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange} 
                                        value={formData.paysEnt || ""}
                                    />
                                </div>
                            </div>
                            <div className='flex mx-4'>
                                <div className="mb-4 mr-[3%] w-[30%]">
                                    <label htmlFor="emailEnt" className="block text-sm font-medium text-marineLogo">Email standard :</label>
                                    <input 
                                        type="text" 
                                        name="emailEnt" 
                                        id="emailEnt" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange} 
                                        value={formData.emailEnt || ""}
                                    />
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="numEnt" className="block text-sm font-medium text-marineLogo">Téléphone standard :</label>
                                    <input 
                                        type="text" 
                                        name="numEnt" 
                                        id="numEnt" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none " 
                                        onChange={handleChange} 
                                        value={formData.numEnt || ""}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="mb-4 mx-4">
                                    <label htmlFor="material" className="block text-sm font-medium text-roseLogo">Type de besoin matériel :</label>
                                    <div className='flex items-center mx-4 '>
                                        <div className=' w-[30%]'>
                                            <input list="materialsList"
                                                name="material"
                                                id="material"
                                                className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none "
                                                onChange={(e) => setCurrentMaterial(e.target.value)}
                                                value={currentMaterial}
                                            />
                                            <datalist id="materialsList">
                                                {activiteData.map((act, index) =>
                                                    <option>{act.label}</option>
                                                )}
                                            </datalist>
                                        </div>
                                        <div className=''>
                                            <button
                                                type="button"
                                                className="ml-2 px-3 py-1 rounded-md hover:border hover:border-vertLogo focus:outline-none border border-roseLogo"
                                                onClick={handleAddMaterial}
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {materials.length > 0 && (
                                    <div className="mb-4">
                                        <ul>
                                            {materials.map((material, index) => (
                                            <li key={index}>{material}</li>
                                            ))}
                                        </ul>
                                        </div>
                                )}
                            </div>
                            <div className=' mx-4'>
                                <label className="block text-sm font-medium text-marineLogo">Points de départs principaux :</label>
                                <div className="flex mx-4">
                                    <input
                                        type="text"
                                        name="paysText"
                                        id="paysText"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block  mr-[3%] w-[30%] rounded-md border border-marineLogo focus:outline-none"
                                        value={currentDep.pays}
                                        onChange={(e) => setCurrentDep({ ...currentDep, pays: e.target.value })}
                                        placeholder="Pays de départ"
                                    />
                                    <input
                                        type="text"
                                        name="villeText"
                                        id="villeText"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block  mr-[3%] w-[30%] rounded-md border border-marineLogo focus:outline-none"
                                        value={currentDep.ville}
                                        onChange={(e) => setCurrentDep({ ...currentDep, ville: e.target.value })}
                                        placeholder="Ville de départ"
                                    />
                                    <input
                                        type="text"
                                        name="cpText"
                                        id="cpText"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block  w-[30%] rounded-md border border-marineLogo focus:outline-none"
                                        value={currentDep.cp}
                                        onChange={(e) => setCurrentDep({ ...currentDep, cp: e.target.value })}
                                        placeholder="Code postal départ"
                                    />
                                    <button
                                        type="button"
                                        className="ml-2 px-3 py-1 rounded-md hover:border hover:border-vertLogo focus:outline-none border border-marineLogo"
                                        onClick={handleAddDep}
                                    >
                                        +
                                    </button>
                                </div>
                                <ul>
                                    {deps.map((dep, index) => (
                                        <li key={index}>
                                        {dep.pays} {dep.ville} {dep.cp}
                                        </li>
                                    ))}
                                </ul>
                                
                            </div>          
                        </fieldset>
                    ): null}

                    

                    {/** Si champ 1 à été selectionner sur "prospect" ou "client-effctif" ou "nouveau-prospect" */}
                    {selectedOption === 'prospect' || selectedOption === 'client-effectif' || selectedOption === 'parent' || selectedOption === 'nouveau-prospect' ? (
                        <fieldset className="mb-4 flex flex-col ml-4">
                            <legend className="text-lg font-semibold mb-2 text-marineLogo">
                                Déroulé de la visite
                            </legend>
                            <div className="mb-4 mx-4">
                                <div>
                                    <label className='text-marineLogo'>Interlocuteurs/trices</label>
                                    <div>
                                        <div className='flex'>
                                            <div className="mb-4 mr-[3%] w-[30%]">
                                                <label htmlFor="nom" className="block text-sm font-medium text-roseLogo">Nom :</label>
                                                <input 
                                                    type="text" 
                                                    name="nom" 
                                                    id="nom" 
                                                    className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none" 
                                                    onChange={handleNameChange}
                                                    value={formData.nom}
                                                    list="clientNames"
                                                    required
                                                />
                                                <datalist id="clientNames">
                                                    {clientContact.map((client, index) => (
                                                    <option key={index} value={`${client.nom} ${client.prenom}`}>
                                                        {client.nom} {client.prenom}
                                                    </option>
                                                    ))}
                                                </datalist>
                                            
                                            </div>
                                            <div className="mb-4 mr-[3%] w-[30%]">
                                                <label htmlFor="prenom" className="block text-sm font-medium text-roseLogo">Prénom :</label>
                                                <input 
                                                    type="text"
                                                    name="prenom" 
                                                    id="prenom" 
                                                    className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none" 
                                                    onChange={handleChange} 
                                                    value={formData.prenom}
                                                    required 
                                                />
                                            </div>
                                            <div className="mb-4 w-[30%]">
                                                <label htmlFor="fonction" className="block text-sm font-medium text-roseLogo">Fonction :</label>
                                                <input 
                                                    type="text" 
                                                    name="fonction" 
                                                    id="fonction" 
                                                    className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none" 
                                                    onChange={handleChange} 
                                                    value={formData.fonction}
                                                    required 
                                                />
                                            </div>
                                        </div>
                                        <div className='flex'>
                                            <div className="mb-4 mr-[3%] w-[30%]">
                                                <label htmlFor="email" className="block text-sm font-medium text-roseLogo">Email interlocuteur :</label>
                                                <input 
                                                    type="email" 
                                                    name="email" 
                                                    id="email" 
                                                    className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none" 
                                                    onChange={handleChange} 
                                                    value={formData.email}
                                                    required 
                                                />
                                            </div>
                                            <div className="mb-4 w-[30%]">
                                                <label htmlFor="num" className="block text-sm font-medium text-roseLogo">Telephone interlocuteur :</label>
                                                <input 
                                                    type="size" 
                                                    maxlength='10'
                                                    name="num" 
                                                    id="num" 
                                                    className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none " 
                                                    onChange={handleChange} 
                                                    value={formData.num}
                                                    required 
                                                />
                                            </div>
                                            <input type="hidden" name="idContact" id="idContact" value={formData.idContact} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="mb-4 mr-12">
                                    <label htmlFor="topics" className="block text-sm font-medium text-roseLogo">Sujets évoqués :</label>
                                    <textarea 
                                        name="topics" 
                                        id="topics" 
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none" 
                                        onChange={handleChange}  
                                        value={ formData.topics }
                                        required  
                                        rows="10"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="steps" className="block text-sm font-medium text-roseLogo">
                                        Étapes suivantes :
                                    </label>
                                    <div className="flex mx-4 ">
                                        <input
                                            type="text"
                                            name="stepText"
                                            id="stepText"
                                            className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block  mr-[3%] w-[30%] rounded-md border border-roseLogo focus:outline-none"
                                            placeholder="Ajouter une étape"
                                            onChange={(e) => setCurrentStep({ ...currentStep, text: e.target.value })}
                                            value={currentStep.text}
                                        />
                                        <input
                                            type="date"
                                            name="stepDate"
                                            id="stepDate"
                                            className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-[30%] rounded-md border border-roseLogo focus:outline-none"
                                            placeholder="Date"
                                            onChange={(e) => setCurrentStep({ ...currentStep, date: e.target.value })}
                                            value={currentStep.date}
                                            
                                        />
                                        <button
                                            type="button"
                                            className="ml-2 px-3 py-1 hover:border hover:border-vertLogo rounded-md focus:outline-none border border-roseLogo"
                                            onClick={handleAddStep}
                                        >
                                            +
                                        </button>
                                    </div>
                                    <ul>
                                    {steps.map((step, index) => (
                                        <li key={index}>
                                        {step.text} ({step.date})
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                                <div className="mb-4 w-[30%]">
                                    <label htmlFor="nextVisitDate" className="block text-sm font-medium text-roseLogo">Date de la prochaine visite :</label>
                                    <input 
                                        type="date" 
                                        name="nextVisitDate" 
                                        id="nextVisitDate" 
                                        required  
                                        className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-roseLogo focus:outline-none" 
                                        onChange={handleChange}
                                        value={formData.nextVisitDate}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    ) : null }
                    {/** Si champ 1 à été selectionner sur "docE" ou "docN" */}
                    {selectedOption === 'docE' || selectedOption === 'docN' ? (
                        <div className='flex ml-4'>
                            <div className="mb-4 mr-[3%] w-[30%]">
                                <label htmlFor="clients" className="block text-sm font-medium text-marineLogo">Ajouter le document:</label>
                                <input
                                    type="file"
                                    name="docExt"
                                    id="docExt"
                                    className=" mx-4 hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marinelogo focus:outline-none"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    ): null}
                    {selectedOption  ? (
                        <div className="flex justify-end">
                            <button type="submit" onClick={checkConnection} className='bg-vertLogo w-[20%] flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo'>
                                Soumettre
                            </button>
                            <button
                                type="button"
                                className="hover:border hover:border-roseLogo hover:bg-blanc hover:text-roseLogo mr-2 px-4 py-2 rounded-md border border-marineLogo text-marineLogo bg-blanc focus:outline-none"
                                onClick={handleCancel}
                            >
                                Annuler
                            </button>
                        </div>
                    ) : null }
                </form>
            </div>
            ): null}  
        </div>
    </div>
    );
}