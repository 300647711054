import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import db from '../../Db/db';

function Top({ block, submitData }) {
    const [rowCount, setRowCount] = useState(10);
    const [filtreData, setFiltreData] = useState([]);
    const [filtreClients, setFiltreClients] = useState([]);
    const [clientAct, setClientAct] = useState([]);

    const handleRowCountChange = (event) => setRowCount(parseInt(event.target.value, 10));

    let clientData = [];

    useEffect(() => {
        async function fetchData() {
            const [data2, data3, data4] = await Promise.all([
                db.act.toArray(),
                db.top.toArray(),
                db.clients.toArray()
            ]);
            setClientAct(data2);
            setFiltreData(data3);
            setFiltreClients(data4);
        }
        fetchData();
    }, []);

    const processFilterData = () => {
        let filteredData = [...filtreData];
        let caNull = '0';
    
        // Filtre par client et agence via searchTerm
        if (submitData?.searchTerm) {
            const searchTerms = submitData.searchTerm.split(' / ');
            const client = searchTerms[0];
            const agence = searchTerms[2];
            
            console.log("Filtering by searchTerm:", submitData.searchTerm);
            
            filteredData = filteredData.filter(item => item.codeClient === client && item.codeAgence === agence);
            console.log("After searchTerm filter:", filteredData);
            
            if (filteredData.length === 0) {
                caNull = '1';
                filteredData = filtreClients.filter(item => item.codeClient === client && item.codeAgence === agence);
                console.log("Filtered by clients (caNull):", filteredData);
            }
        }
    
        // Filtre par agence
        if (submitData?.selectedAgence) {
            console.log("Filtering by selectedAgence:", submitData.selectedAgence);
            
            filteredData = filteredData.filter(item => item.codeAgence === submitData.selectedAgence);
            console.log("After selectedAgence filter:", filteredData);
        }
    
        // Filtre par options sélectionnées
        if (submitData?.selectedOptions?.length > 0) {
            console.log("Filtering by selectedOptions:", submitData.selectedOptions);
            
            const selectedActMF = clientAct.filter(item =>
                item.idActMF && submitData.selectedOptions.some(option => item.idActMF.includes(option.value.toString()))
            );
            
            filteredData = filteredData.filter(item =>
                selectedActMF.some(client => client.codeClient === item.codeClient && client.codeAgence === item.codeAgence)
            );
            console.log("After selectedOptions filter:", filteredData);
        }
    
        // Filtre par commercial
        if (submitData?.commercial.length > 0) {
            console.log("Filtering by commercial:", submitData.commercial);
            
            filteredData = filteredData.filter(item => item.commercial === submitData.commercial);
            console.log("After commercial filter:", filteredData);
        }
    
        // Filtre par date
        if (submitData?.startDate && submitData?.endDate && caNull === '0') {
            const startYear = new Date(submitData.startDate).getFullYear();
            const startMonth = new Date(submitData.startDate).getMonth();
            const endYear = new Date(submitData.endDate).getFullYear();
            const endMonth = new Date(submitData.endDate).getMonth();
            
            console.log("Filtering by date:", submitData.startDate, submitData.endDate);
            
            filteredData = filteredData.filter(item =>
                item.annee >= startYear && item.annee <= endYear &&
                item.mois >= startMonth && item.mois <= endMonth
            );
            console.log("After date filter:", filteredData);
        }
    
        return filteredData;
    };

    const aggregateDataByClientAndAgence = (data) => {
        const sumByClientAndAgence = {};

        data.forEach(item => {
            const { codeClient, codeAgence, CA, nom, nomClient, commercial } = item;
            if (!sumByClientAndAgence[codeClient]) {
                sumByClientAndAgence[codeClient] = {};
            }
            if (!sumByClientAndAgence[codeClient][codeAgence]) {
                sumByClientAndAgence[codeClient][codeAgence] = {
                    sumCA: 0,
                    nom,
                    nomClient,
                    commercial
                };
            }
            const caValue = CA !== null ? parseFloat(CA) : 0;
            sumByClientAndAgence[codeClient][codeAgence].sumCA += caValue;
        });

        return Object.entries(sumByClientAndAgence).flatMap(([codeClient, agences]) =>
            Object.entries(agences).map(([codeAgence, data]) => ({
                codeClient,
                codeAgence,
                ...data
            }))
        );
    };

    // Main logic for submitting data
    if (submitData && Object.keys(submitData).length > 0) {
        const filteredData = processFilterData();
        const aggregatedData = aggregateDataByClientAndAgence(filteredData);
        clientData = aggregatedData.sort((a, b) => b.sumCA - a.sumCA).slice(0, 30);
    } else {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        const filteredData = filtreData.filter(top =>
            top.annee === currentYear && top.mois >= 1 && top.mois <= currentMonth
        );
        const aggregatedData = aggregateDataByClientAndAgence(filteredData);
        clientData = aggregatedData.sort((a, b) => b.sumCA - a.sumCA).slice(0, 30);
    }

    return (
        <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-full w-[100%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                <span>Mon top </span>
                <select
                    id="rowCount"
                    className="px-2 py-1 border rounded text-xs"
                    value={rowCount}
                    onChange={handleRowCountChange}
                >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
            </div>
            <div className='flex items-center justify-center'>
                <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[98%] border-collapse ">
                    <thead className='h-[6%] max-h-1.5 text-blanc'>
                        <tr className='bg-marineLogo'>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left w-[60%]">Nom client</th>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left w-[20%]">Agence</th>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center w-[20%]">CA N</th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto max-h-[94%] min-h-[50%]'>
                        {clientData.slice(0, rowCount).map((member, index) => (
                            <tr key={index} className='max-h-[10%] min-h-[5%]'>
                                <Link to={`/profil/client/${member.codeClient}/${member.codeAgence}`} className="focus:outline-none text-left text-marineLogo font-bold flex justify-start items-center w-full h-full p-[1%]">
                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">{member.nomClient} ({member.codeClient})</td>
                                </Link>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">{member.nom}</td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap pr-[5%] text-right">
                                    {isNaN(parseFloat(member.sumCA)) ? "0,00" : member.sumCA.toLocaleString('fr-FR', { minimumFractionDigits: 2 })} €
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Top;
