import React, { useState, useEffect } from 'react';
import axios from "axios";
function FormAffectClient({url, token, onSubmitSuccess}) {
    
    const [clients, setClients] = useState([]);
    const [commerciaux, setCommerciaux] = useState([]);
    const [currentClient, setCurrentClient] = useState('');
    const [mergeClient, setMergeClient] = useState([]);



    

    const resetForm = () => {
        setCurrentClient([]);
        
        setMergeClient([]);

        document.getElementById('commercial').value = '';
        document.getElementById('enfant').value = '';
      };

    useEffect(() => {
        
        axios.get(url+'client/allClients/', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {         
            setClients(res.data);
            
        }).catch(error=>{
            console.log(error);
            
        });

        axios.get(url+'team/', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + token
            }
        }).then(res => {         
            setCommerciaux(res.data);
            
        }).catch(error=>{
            console.log(error);
            
        });
        
    }, [url]);
   

    const submit = async (e) => {
        e.preventDefault();
        console.log(e)
        const codeCommercial = e.target.commercial.value;
      
        try {
            await axios.put(url + 'client/affect', {
              clients: mergeClient,
              codeCommercial: codeCommercial,
            }, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + token
              }
            });
            alert('Les clients ont bien été affécté !');
            onSubmitSuccess()
            resetForm()
        } catch (error) {
            console.error(error);
        }
    };

    const handleAddClients = () => {
        if (currentClient !== '') {
            setMergeClient([...mergeClient, currentClient]);
          setCurrentClient('');
        }
      };
    
    return(
        <div className="w-full flex h-[90%]">
            <div className="bg-blanc w-full max-lg:w-full p-4 rounded-lg shadow-lg border border-marineLogo min-h-[20%] max-h-[80%] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100">
                <h2 className="text-[100%] font-semibold text-marineLogo mb-4">Formulaire Affectation clients</h2>
                <form onSubmit={submit} className='flex flex-col' id='monFormulaire'>
                    <div>
                        <div className='flex'>
                            <div className="mb-4 mr-[3%] w-[90%]">
                                <label htmlFor="commercial" className="block text-sm font-medium text-grey">Commercial :</label>
                                <select
                                    id="commercial"
                                    className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                    //value={commerciaux}
                                    placeholder="Commercial"
                                    //onChange={handleCommercialChange}
                                >
                                    <option value=""></option>
                                    {commerciaux.map((t, index) => (
                                        <option key={index} value={t.idUser}>{t.name} {t.firstName}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-4">
                            <div className="mb-4">
                                
                                <label htmlFor="enfant" className="block text-sm font-medium text-grey">Les clients :</label>
                                <div className='flex items-center mb-4'>
                                    <div className=' w-[90%]'>
                                        <input list="enfantsList"
                                        name="enfant"
                                        id="enfant"
                                        className="hover:border hover:border-vertLogo focus:border focus:border-vertLogo mt-1 block w-full rounded-md border border-marineLogo focus:outline-none"
                                        value={currentClient}
                                        onChange={(e) => setCurrentClient(e.target.value)}
                                        />
                                        <datalist id="enfantsList">
                                        {clients.map((client, index) => (
                                            <option key={index} value={`${client.codeClient} - ${client.codeAgence} - ${client.nomClient}`} />
                                        ))}
                                        </datalist>
                                    </div>
                                    <div className=''>
                                        <button
                                        type="button"
                                        className=" ml-1 mt-1 px-3  bg-blue-500 border border-marineLogo text-marineLogo rounded-md hover:bg-vertLogo hover:text-blanc hover:border hover:border-vertLogo focus:outline-none"
                                        onClick={handleAddClients}
                                        >
                                        +
                                        </button>
                                    </div>
                                </div>
                                
                            </div>
                            {mergeClient.length > 0 && (
                            <div className="mb-4">
                                <ul>
                                    {mergeClient.map((material, index) => (
                                    <li key={index}>{material}</li>
                                    ))}
                                </ul>
                            </div>
                            )}

                        </div>
                        
                    </div>
                    <div className="flex justify-end">
                    <button
                        type="button"
                        className="hover:border hover:border-roseLogo hover:bg-blanc hover:text-roseLogo mr-2 px-4 py-1 rounded-md border border-marineLogo text-marineLogo bg-blanc focus:outline-none"
                        onClick={resetForm}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-vertLogo w-[20%] flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                    >
                        Affecter
                    </button>
                </div>
                </form>
            </div>
        </div>
    )
}
export default FormAffectClient