import React, {useState, useEffect} from 'react';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import '../../assets/css/profil.css';
import ReactModal from 'react-modal';
import db from '../../Db/db';
import doc from "../../assets/Doc.pdf";


function Profils({url}) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageModalOpen, setImageModalOpen] = useState(false);
    

    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);
    const [dataUser, setDataUser] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();

    const handleImageClick = () => {
        setImageModalOpen(true);
    };
    const handleImageUpload = (e) => {
    e.preventDefault();
        const doc = document.getElementById('imgProf');

          
        axios.post(url +'users/profilsUrl/'+id, {
            id: id,
            url: doc.files[0].name,
            imgProf: doc.files[0]


        },{
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": 'multipart/form-data',
                'Authorization': 'Bearer ' + userInfo.token
            }
        }).then(res => {
            
        }).catch(function (error) {
            if (error.response.status == 401){
                navigate("/login");
            }
            console.log(error.response.status);
        });

        axios.get(`https://api.hautier-transports.fr/crm/profilImg/${doc.files[0].name}`, {
            responseType: 'blob', 
          }).then((response) => {
            const imgBlobURL = URL.createObjectURL(response.data);

            setSelectedImage(imgBlobURL)

          })
          .catch((error) => {
            console.error('Erreur lors du téléchargement du fichier :', error);
          });
        setImageModalOpen(false)
    };

    const logout = async () => {
        await db.delete();
        localStorage.clear();
        navigate("/login");
      };

    const changePass = (e) => {
        e.preventDefault(); 
        axios.put(url+'users/upd/'+id,{
            password: e.target.newPass.value,
            password_confirm: e.target.newPassConf.value
        }, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {         
            alert("Votre Mot de passe à bien été changé!")
            
        })
        .catch(error=>{
            console.log(error.response.status);
        });
    }

      useEffect(() => {
        

        
    
        axios.get(url+'users/'+id, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {         
            setDataUser(res.data)
            axios.get(url+`/profilImg/${res.data.urlProfil}`, {
                responseType: 'blob', 
              }).then((response) => {
                const imgBlobURL = URL.createObjectURL(response.data);
    
                setSelectedImage(imgBlobURL)
    
              })
              .catch((error) => {
                console.error('Erreur lors du téléchargement du fichier :', error);
              });
            
        })
        .catch(error=>{
            if(error.response.status == 401){
                navigate("/login");
            }
            
        });

        }, [url]);
          

    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full">
                <div className="h-[15%] w-full">
                    <Title title="Mon Espace" btn={false} />
                </div>
                <div className="mt-4 h-[85%] w-full lg:p-16 sm:pr-4">
                    <div className=" bg-white shadow mt-24">
                        <div className='relative'>
                            <div className=' absolute right-0'>
                            { id == userInfo.user.idUser ? (
                                <div  className="max-sm:hidden space-x-8 z-1">
                                    <button type='submit' onClick={logout}
                                    className="text-blanc py-2 px-4 uppercase rounded bg-vertLogo hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo font-bahn"
                                    >
                                    Deconnexion
                                    </button>
                                </div> ) : null}
                            </div>
                            <div className='absolute left-0'> 
                                <a href={doc} target="_blank" rel="noopener noreferrer">
                                    <button type='button' className="text-blanc py-2 px-4 uppercase rounded bg-vertLogo hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo font-bahn">
                                    ?
                                    </button>
                                </a>
                            </div>
                            <div  className="flex justify-center z-1">
                                
                            <div className="oui hoover lg:w-48 lg:h-48 max-lg:w-24 max-lg:h-24 rounded-full shadow-2xl -mt-24 flex items-center justify-center relative">
                            
                            {selectedImage ? (
                                <img src={selectedImage} className='clip-path-mycircle absolute' alt="Profile" />
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-full h-full clip-path-mycircle absolute">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                            )}
                            { id == userInfo.user.idUser ? (
                            <div className='max-sm:hidden lg:w-48 lg:h-48 max-lg:w-24 max-lg:h-24 items-center rounded-full'>
                                <svg onClick={handleImageClick} className='ico text' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                </svg>
                            </div> ) : null}
                        </div>
                            </div>
                        </div>
                        { id == userInfo.user.idUser ? (
                        <ReactModal
                            isOpen={isImageModalOpen}
                            onRequestClose={() => setImageModalOpen(false)}
                            contentLabel="Sélectionner une image de profil"
                            className="mx-auto my-4 w-[25%] bg-blanc rounded-lg shadow-lg p-4"
                        >
                            <form encType="multipart/form-data" onSubmit={handleImageUpload} className='flex flex-col' id='monFormulaire'>
                                <h2 className="text-2xl font-semibold mb-4">Sélectionner une image de profil</h2>
                                <input type="file" accept="image/*" name='imgProf' id='imgProf' className="mb-4 p-2 border rounded" />
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-bleu text-white rounded-md  focus:outline-none"
                                >
                                    Valider
                                </button>
                            </form>
                        </ReactModal>) : null}
                        <div className="lg:mt-6 text-center">
                            <h1 className="text-4xl font-bahn text-gray-700">{dataUser.firstName} {dataUser.name}</h1>
                        </div>
                        <div className='mt-12 flex justify-around w-full'>
                            <div className="p-6 ">
                                <div className='space-y-4'>
                                    <div className='flex space-x-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                                        </svg>
                                        <span className='font-bahn'>{dataUser.email}</span>
                                    </div>
                                    <div className='flex space-x-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                                        </svg>
                                        <span className='font-bahn'>{dataUser.title}</span>
                                    </div>
                                    <div className='flex space-x-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525" />
                                    </svg>
                                        <span className='font-bahn'>{dataUser.adresse}</span>
                                    </div>
                                </div>
                            </div>

                            { id == userInfo.user.idUser ? (
                            <div className='max-sm:hidden p-6 md:border-l md:w-1/2'>
                                <form onSubmit={changePass}>
                                <div>
                                    <div>
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bahn mb-2">
                                        Mot de passe actuel
                                    </label>
                                        <input className='appearance-none border hover:border hover:border-vertLogo focus:border focus:border-vertLogo rounded py-2 w-1/2 px-4 mb-3 focus:outline-none' type="password" name='actualPass' id='actualPass' placeholder="mot de passe courant" />
                                    </div>
                                    <div className='flex space-x-2'>
                                        <div className='w-1/2'>
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bahn mb-2">
                                                Nouveau mot de passe

                                            </label>
                                            <input className='w-full appearance-none border hover:border hover:border-vertLogo focus:border focus:border-vertLogo rounded py-2 px-4 mb-3 focus:outline-none' name='newPass' id='newPass' type="password" placeholder="nouveau mot de passe courant" />
                                        </div>
                                        <div className='w-1/2'>
                                            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bahn mb-2">
                                                Confirmation du mot de passe
                                            </label>
                                            <input className='w-full appearance-none border hover:border hover:border-vertLogo focus:border focus:border-vertLogo rounded py-2 px-4 mb-3 focus:outline-none' name='newPassConf' id='newPassConf' type="password" placeholder="confirmer mot de passe courant" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button type='submit' className="text-blanc py-2 px-4 uppercase rounded bg-vertLogo hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo f">Enregistrer</button>
                                </div>
                                </form>
                            </div>
                            ): null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profils;;