import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import { Link } from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import GraphClients from '../Containers/graphClients';
import db from '../../Db/db';

export default function Cas() {
    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);

    const [submitData, setSubmitData] = useState([]);
    const [data, setData] = useState([]);
    const [clientsData, setClientsData] = useState([]);

    const handleSubmit = (submitFilterData) => {
        setSubmitData(submitFilterData);
    };

    useEffect(() => {
        async function fetchData() {
            const data1 = await db.caYear.toArray();
            setData(data1);
            const data2 = await db.clients.toArray();
            setClientsData(data2);
        }
        fetchData();
    }, []);

    const startDate = new Date(submitData.startDate);
    const endDate = new Date(submitData.endDate);
    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endMonth = endDate.getMonth();

    const months = ['Jan', 'Fev', 'Mar', 'Apr', 'Mai', 'Jui', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const filterYearsAndMonths = (client, startYear, endYear, startMonth, endMonth) => {
        const filteredAnnees = {};
    
        Object.keys(client.annees).forEach(year => {
            const intYear = parseInt(year);
    
            // Si l'année est complètement dans la période
            if (intYear > startYear && intYear < endYear) {
                filteredAnnees[year] = client.annees[year];
            }
            // Si l'année est la première année de la période
            else if (intYear === startYear) {
                const filteredMonths = {};
                Object.keys(client.annees[year]).forEach(month => {
                    const intMonth = parseInt(month) - 1;
                    if (intMonth >= startMonth) {
                        filteredMonths[month] = client.annees[year][month];
                    }
                });
                if (Object.keys(filteredMonths).length > 0) {
                    filteredAnnees[year] = filteredMonths;
                }
            }
            // Si l'année est la dernière année de la période
            else if (intYear === endYear) {
                const filteredMonths = {};
                Object.keys(client.annees[year]).forEach(month => {
                    const intMonth = parseInt(month) - 1;
                    if (intMonth <= endMonth) {
                        filteredMonths[month] = client.annees[year][month];
                    }
                });
                if (Object.keys(filteredMonths).length > 0) {
                    filteredAnnees[year] = filteredMonths;
                }
            }
        });
    
        return filteredAnnees;
    };

    // Fonction pour récupérer les mois entre startDate et endDate
    const getMonthsBetweenDates = (startDate, endDate) => {
        const start = new Date(startDate.getFullYear(), startDate.getMonth(), 1);
        const end = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
        const monthsInRange = [];

        while (start <= end) {
            
            const month = start.getMonth() + 1;
            const alreadyExists = monthsInRange.some(m => m.month === month);

            if (!alreadyExists) {
                monthsInRange.push({month });
            }
            start.setMonth(start.getMonth() + 1);
        }

        return monthsInRange;
    };

    const filteredMonths = getMonthsBetweenDates(startDate, endDate);
    let clients = [];
    
    if (submitData) {
        const { searchTerm, selectedAgence, selectedOptions, commercial } = submitData;
    
        if (searchTerm !== undefined && searchTerm.length > 0) {
            if (searchTerm.includes('_PARENT')) {
                console.log(1)
                const allEnfants = clientsData.filter(cli => 
                    cli.codeClient === searchTerm.split(' / ')[0] && 
                    cli.codeAgence === searchTerm.split(' / ')[2]
                );
                allEnfants.forEach(client => {
                    if (client.enfants && client.enfants.length > 0) {
                        const filteredData = data.filter(item =>
                            client.enfants.some(enfant =>
                                item.codeClient === enfant.enfant && item.codeAgence === enfant.agence
                            )
                        );
                        clients.push(...filteredData);
                    }
                });
            } else {
                console.log(2)
                
                clients = data.filter(topi => 
                    topi.codeClient === searchTerm.split(' / ')[0] && 
                    topi.codeAgence === searchTerm.split(' / ')[2]
                );
            }
            console.log(clients)
            clients = clients.map(client => {
                const filteredCurrentYears = filterYearsAndMonths(client, startYear, endYear, startMonth, endMonth);
                const filteredPreviousYears = filterYearsAndMonths(client, startYear, endYear - 1, startMonth, endMonth);
            
                return {
                    ...client,
                    annees: {
                        ...filteredPreviousYears,
                        ...filteredCurrentYears
                    }
                };
            }).filter(client => Object.keys(client.annees).length > 0);

            console.log(clients)
        } else if (searchTerm !== undefined && searchTerm.length === 0 && selectedAgence.length > 0) {
            console.log(3)
            clients = data.filter(client => client.codeAgence === selectedAgence);
    
            clients = clients.map(client => {
                const filteredCurrentYears = filterYearsAndMonths(client, startYear, endYear, startMonth, endMonth);
                const filteredPreviousYears = filterYearsAndMonths(client, startYear, endYear - 1, startMonth, endMonth);
            
                return {
                    ...client,
                    annees: {
                        ...filteredPreviousYears,
                        ...filteredCurrentYears
                    }
                };
            }).filter(client => Object.keys(client.annees).length > 0);
    
        } else if (searchTerm !== undefined && searchTerm.length === 0 && selectedAgence.length === 0 && selectedOptions.length > 0) {
            console.log(4);
            // Traitement basé sur selectedOptions
    
        } else if (searchTerm !== undefined && searchTerm.length === 0 && selectedAgence.length === 0 && commercial.length > 0) {
            console.log(5)
            clients = data.filter(client => client.commercial === commercial);
    
            clients = clients.map(client => {
                const filteredCurrentYears = filterYearsAndMonths(client, startYear, endYear, startMonth, endMonth);
                const filteredPreviousYears = filterYearsAndMonths(client, startYear, endYear - 1, startMonth, endMonth);
            
                return {
                    ...client,
                    annees: {
                        ...filteredPreviousYears,
                        ...filteredCurrentYears
                    }
                };
            }).filter(client => Object.keys(client.annees).length > 0);
    
        } else {
            console.log(6)
            const commercialValue = userInfo.user.role.includes('Assistant')
                ? userInfo.user.role.split(" ")[1]
                : userInfo.user.role === 'Administrateur'
                    ? null 
                    : userInfo.user.idUser;
    
            clients = data.filter(client => 
                userInfo.user.role === 'Administrateur' || client.commercial === commercialValue
            );
    
            clients = clients.map(client => {
                const filteredCurrentYears = filterYearsAndMonths(client, startYear, endYear, startMonth, endMonth);
                const filteredPreviousYears = filterYearsAndMonths(client, startYear, endYear - 1, startMonth, endMonth);
            
                return {
                    ...client,
                    annees: {
                        ...filteredPreviousYears,
                        ...filteredCurrentYears
                    }
                };
            }).filter(client => Object.keys(client.annees).length > 0);
        }
    }

    const calculatePercentage = (current, previous) => {
        if (previous === 0) return current === 0 ? 0 : 100;
        return ((current - previous) / previous * 100).toFixed(2);
    };

    const totalCA_N = new Array(12).fill(0);
    const totalCA_N_1 = new Array(12).fill(0);
    let overallTotalCA_N = 0;
    let overallTotalCA_N_1 = 0;

    clients.forEach(client => {
        for (let intMonth = 0; intMonth < 12; intMonth++) {
            
            if (client.annees[endYear] && client.annees[endYear][intMonth + 1]) {
                const caValue = parseFloat(client.annees[endYear][intMonth + 1].ca) || 0;
                totalCA_N[intMonth] += caValue;
                overallTotalCA_N += caValue;
            } else {
                totalCA_N[intMonth] += 0;
            }
    
            const previousYear = (endYear - 1).toString();
            if (client.annees[previousYear] && client.annees[previousYear][intMonth + 1]) {
                const caN1Value = parseFloat(client.annees[previousYear][intMonth + 1].ca) || 0;
                totalCA_N_1[intMonth] += caN1Value;
                overallTotalCA_N_1 += caN1Value;
            } else {
                totalCA_N_1[intMonth] += 0;
            }
        }
    });
    
    overallTotalCA_N = parseFloat(overallTotalCA_N) || 0;
    overallTotalCA_N_1 = parseFloat(overallTotalCA_N_1) || 0;


    let maxYear = null;
    let prevYear = null;

    clients.forEach(client => {
        Object.keys(client.annees).forEach(year => {
            const intYear = parseInt(year);
            if (!maxYear || intYear > maxYear) {
                maxYear = intYear;
            }
            if (!prevYear || intYear < prevYear) {
                prevYear = intYear;
            }
        });
    });

    const totalEvolution = totalCA_N.map((ca, index) => calculatePercentage(ca, totalCA_N_1[index]));
    const overallTotalEvolution = calculatePercentage(overallTotalCA_N, overallTotalCA_N_1);

    const calculateTotalCA_N = (yearData) => {
        if (!yearData) return 0;
    
        return Object.keys(yearData).reduce((total, month) => {
            if (yearData[month] && yearData[month].ca) {
                return total + parseFloat(yearData[month].ca);
            } else {
                return total;
            }
        }, 0);
    };
    const chartData = {
        labels: months,  // les mois de l'année
        datasets: [
            {
                label: `Chiffre d'affaires N (${endYear})`,
                data: totalCA_N,  // valeurs pour l'année N
                fill: false,
                borderColor: 'rgb(148, 206, 247)',
                backgroundColor: 'rgb(148, 206, 247)',
                tension: 0.1
            },
            {
                label: `Chiffre d'affaires N-1 (${endYear - 1})`,
                data: totalCA_N_1,  // valeurs pour l'année N-1
                fill: false,
                borderColor: 'rgb(241, 110, 110)',
                backgroundColor: 'rgb(241, 110, 110)',
                tension: 0.1
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Comparaison du Chiffre d\'Affaires N et N-1'
            }
        }
    };

    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Chiffre d'affaires" btn={false} handleExportExcel={clients} onSubmitFilter={handleSubmit} />
                </div>
                <div className='mt-4 h-[80%] w-full px-1 pb-4'>
                    <div className="flex h-[5%] w-full">
                        <div className=" h-full w-full">
                            <table className="max-h-[70%] w-[98%] border-collapse items-center bg-transparent border-collapse">
                                <thead className='h-[6%] max-h-1.5 text-blanc'>
                                    <tr className='bg-marineLogo h-full w-full'>
                                        <th className="align-middle text-blanc text-[70%] uppercase whitespace-nowrap w-[14%]">Clients</th>
                                        <th className="align-middle text-blanc text-[70%] uppercase whitespace-nowrap w-[5.73%]">Agence</th>
                                        <th className="align-middle text-blanc text-[70%] uppercase whitespace-nowrap w-[5.73%]"></th>
                                        {filteredMonths.map(({ month }, index) => (
                                            <th key={index} className="align-middle text-blanc text-[70%] uppercase whitespace-nowrap w-[5.73%]">
                                                {months[month - 1]}
                                            </th>
                                        ))}
                                        <th className="align-middle text-blanc text-[70%] uppercase whitespace-nowrap w-[5.73%]">Total</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    <div className="flex w-[99%] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full max-w-[99%]">
                        <table className="h-full w-full text-[70%] bg-blanc">
                            <tbody className='h-full w-full bg-blanc'>
                            {clients.map((client, clientIndex) => (
                                <React.Fragment key={clientIndex}>
                                    <tr className='border border-t'>
                                        <td className='uppercase text-marineLogo font-bold w-[14%]'>
                                            <Link to={`/profil/client/${client.codeClient}/${client.codeAgence}`}>
                                                <div className='overflow-x-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100' style={{ width: '120px' }}>
                                                    {client.nomClient}
                                                </div>
                                            </Link>
                                        </td>
                                        <td className="p-1 w-[5.73%] text-center">{client.codeAgence}</td>
                                        <td className="p-1 w-[5.73%]"></td>
                                        <td className="p-1 text-center"></td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>CA N</td>
                                        {filteredMonths.map(({ year, month }, index) => (
                                            <td key={`${clientIndex}-${month}-ca-n`} className="p-1 text-center">
                                                {client.annees && client.annees[maxYear] && client.annees[maxYear][month] && client.annees[maxYear][month].ca !== undefined
                                                    ? client.annees[maxYear][month].ca.toFixed(2)
                                                    : '0.00'}
                                            </td>
                                        ))}
                                        <td className="p-1 text-center font-semibold">
                                            {calculateTotalCA_N(client.annees[maxYear]).toFixed(2)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>CA N-1</td>
                                        {filteredMonths.map(({ year, month }, index) => (
                                            <td key={`${clientIndex}-${month}-ca-n-1`} className="p-1 text-center">
                                                {client.annees && client.annees[prevYear] && client.annees[prevYear][month] && client.annees[prevYear][month].ca !== undefined
                                                    ? client.annees[prevYear][month].ca.toFixed(2)
                                                    : '0.00'}
                                            </td>
                                        ))}
                                        <td className="p-1 text-center font-semibold">
                                            {calculateTotalCA_N(client.annees[prevYear]).toFixed(2)}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>Evol</td>
                                        {filteredMonths.map(({ year, month }, index) => (
                                            <td key={`${clientIndex}-${month}-evol`} className={`text-center p-0 w-[5.73%] font-semibold ${calculatePercentage(client.annees?.[maxYear]?.[month]?.ca, client.annees?.[prevYear]?.[month]?.ca) < 0 ? 'text-roseLogo' : 'text-vertLogo'}`}>
                                                {client.annees && client.annees[maxYear] && client.annees[maxYear][month] && client.annees[maxYear][month].ca !== undefined && client.annees[prevYear] && client.annees[prevYear][month] && client.annees[prevYear][month].ca !== undefined
                                                    ? calculatePercentage(client.annees[maxYear][month].ca, client.annees[prevYear][month].ca) + '%'
                                                    : '0.00%'}
                                            </td>
                                        ))}
                                        <td className={`text-center p-0 font-semibold w-[5.73%] ${calculatePercentage(
                                            calculateTotalCA_N(client.annees[maxYear]),
                                            calculateTotalCA_N(client.annees[prevYear])
                                        ) < 0 ? 'text-roseLogo' : 'text-vertLogo'}`}>
                                            {calculateTotalCA_N(client.annees[maxYear]) !== undefined && calculateTotalCA_N(client.annees[prevYear]) !== undefined
                                                ? calculatePercentage(
                                                    calculateTotalCA_N(client.annees[maxYear]),
                                                    calculateTotalCA_N(client.annees[prevYear])
                                                ) + '%'
                                                : '0.00%'}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                                <>
                                    <tr className='border border-t'>
                                        <td className='uppercase font-bold w-[14%]'>Total</td>
                                        <td className="p-1 w-[5.73%]"></td>
                                        <td className="p-1"></td>
                                        {filteredMonths.map(({ month }, index) => (
                                            <React.Fragment key={month}>
                                                <td className="text-center p-0 font-semibold w-[5.73%]">{totalCA_N[month - 1].toFixed(2)}</td>
                                            </React.Fragment>
                                        ))}
                                        <td className="text-center p-0 font-semibold w-[5.73%]">{overallTotalCA_N.toFixed(2)}</td>
                                    </tr>
                                    <tr className='border border-t'>
                                        <td className='uppercase font-bold w-[14%]'>Total N-1</td>
                                        <td className="p-1 w-[5.73%]"></td>
                                        <td className="p-1"></td>
                                        {filteredMonths.map(({ month }, index) => (
                                            <React.Fragment key={month}>
                                                <td className="text-center p-0 font-semibold w-[5.73%]">{totalCA_N_1[month - 1].toFixed(2)}</td>
                                            </React.Fragment>
                                        ))}
                                        <td className="text-center p-0 font-semibold w-[5.73%]">{overallTotalCA_N_1.toFixed(2)}</td>
                                    </tr>
                                    <tr className='border border-t'>
                                        <td className='uppercase font-bold w-[14%]'>Évolution</td>
                                        <td className="p-1 w-[5.73%]"></td>
                                        <td className="p-1"></td>
                                        {filteredMonths.map(({ month }, index) => (
                                            <React.Fragment key={month}>
                                                <td className={`text-center p-0 w-[5.73%] font-semibold ${totalEvolution[month - 1] < 0 ? 'text-roseLogo' : 'text-vertLogo'}`}>
                                                    {totalEvolution[month - 1]}%
                                                </td>
                                            </React.Fragment>
                                        ))}
                                        <td className={`text-center p-0 font-semibold w-[5.73%] ${overallTotalEvolution < 0 ? 'text-roseLogo' : 'text-vertLogo'}`}>
                                            {overallTotalEvolution}%
                                        </td>
                                    </tr>
                                </>
                            </tbody>
                        </table>
                    </div>
                    <div className='w-[40%] h-[40%] mx-4 py-4'>
                        <GraphClients chartData={chartData} chartOptions={chartOptions} /> 
                    </div>                 
                </div>
            </div>
        </div>
    );
}
