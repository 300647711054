import React, { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import fonts from '../../assets/fonts.jpg';
import hautier from '../../assets/hautier.jpg';
import dateFormat from "dateformat"
import GraphCAM from './graphCAM';
import GraphOTM from './graphOTM';
import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable'
import db from '../../Db/db';



function GenPDF({data, codeClient, codeAgence}) {

    const [chart, setChart] = useState([]);
    const [client, setClient] = useState('');
    const [dateDu, setDateDu] = useState('');
    const [dateAu, setDateAu] = useState('');
    const [periodeEquivalente, setPeriodeEquivalente] = useState(false);
    const [clientList, setClientList] = useState([])
    const [selectedSections, setSelectedSections] = useState([]);
    const [interviewDate, setInterviewDate] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    
    const [villeCharg, setCityChargData] = useState([]);
    const [villeLivraison, setCityLivrData] = useState([]);
    
    const [commercial, setCommercial] = useState([]);
    const [teamData, setTeamData] = useState([]);
    
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(true);
    const [unClient, setUnClient] = useState([]);

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
      
        const lastYear = new Date(currentYear, 0, 1);
        const formattedLastYear = `${lastYear.getFullYear()}-${String(lastYear.getMonth() + 1).padStart(2, '0')}-${String(lastYear.getDate()).padStart(2, '0')}`;
      
        const endOfYear = new Date(currentYear, 11, 31);
        const formattedEndOfYear = `${endOfYear.getFullYear()}-${String(endOfYear.getMonth() + 1).padStart(2, '0')}-${String(endOfYear.getDate()).padStart(2, '0')}`;
      
        setDateDu(formattedLastYear);
        setDateAu(formattedEndOfYear);
      }, []);
    
    useEffect(() => {
        async function fetchData() {
            const data1 = await db.clients.toArray();
            setClient(data1);
            const data2 = await db.ca.toArray();
            setChart(data2)
            const data4 = await db.team.toArray();
            setTeamData(data4);
            const data5 = await db.cityCharg.toArray();
            setCityChargData(data5)
            const data6 = await db.cityLivr.toArray();
            setCityLivrData(data6)
        }
        fetchData();
        
    }, []);

    
    
    const chartRef = useRef(null);
    const chartOTM = useRef(null);
    
    const chargement = villeCharg
    const livraison = villeLivraison

    function formatNumberWithThousandsSeparator(number) {

        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    }

    function getMonthName(monthNumber) {
        const monthNames = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
        return monthNames[monthNumber - 1];
    }

    const resetForm = () => {
        setDateDu('');
        setDateAu('');
        setPeriodeEquivalente(false);
        setClientList([]);
        setSelectedSections([]);
        setInterviewDate('');
        setCommercial('');
        setSearchTerm('');
    };

    const generatePDF = async () => {
        setLoading(true)

        if(clientList.length == 1){
            
            const unClient = clientList[0].split(' / ');
            setUnClient(unClient);


            if(clientList.length === 1 && unClient[0].includes('_PARENT')){
                console.log("1 seul client et parent");
                const allenfants = data;
                const all = [];
                const all2 = [];
                const all3 = [];
                
                allenfants.forEach(client => {
                  if (client.enfants && client.enfants.length > 0) {
                    const filteredData = chart.filter(item =>
                      client.enfants.some(enfant =>
                        item.codeClient === enfant.enfant && item.codeAgence === enfant.agence
                      )
                    );
                    all.push(...filteredData);
                
                    const filteredData3 = villeCharg.filter(item =>
                      client.enfants.some(enfant =>
                        item.idClient === enfant.enfant && item.codeAgence === enfant.agence
                      )
                    );
                    all3.push(...filteredData3);
                
                    const filteredData2 = villeLivraison.filter(item =>
                      client.enfants.some(enfant =>
                        item.idClient === enfant.enfant && item.codeAgence === enfant.agence
                      )
                    );
                    all2.push(...filteredData2);
                  }
                });
                
                const totalClient = {
                  cleClient: "TOTAL",
                  codeClient: "TOTAL",
                  nomClient: "TOTAL",
                  mois: []
                };
                
                const totalsByMonth = [];
                console.log(all);
                
                all.forEach(client => {
                  client.mois.forEach((moisData, index) => {
                    if (!totalsByMonth[index]) {
                      totalsByMonth[index] = {
                        ca: 0,
                        ca_n_1: 0,
                        ot: 0,
                        ot_n_1: 0
                      };
                    }
                    totalsByMonth[index].ca += moisData.ca;
                    totalsByMonth[index].ca_n_1 += moisData.ca_n_1;
                    totalsByMonth[index].ot += moisData.ot;
                    totalsByMonth[index].ot_n_1 += moisData.ot_n_1;
                  });
                });
                
                // Appliquer le filtre de période juste avant de définir le total
                const startMonth = new Date(dateDu).getMonth() + 1;
                const endMonth = new Date(dateAu).getMonth() + 1;
                
                totalsByMonth.forEach((totalMonth, index) => {
                  if (new Date(dateDu).getFullYear() == new Date(dateAu).getFullYear()) {
                    // Si "Période équivalente" est cochée, garder uniquement les mois dans la plage
                    if (index + 1 >= startMonth && index + 1 <= endMonth) {
                      totalClient.mois.push({
                        mois: index + 1,
                        ca: totalMonth.ca,
                        ca_n_1: totalMonth.ca_n_1,
                        ot: totalMonth.ot,
                        ot_n_1: totalMonth.ot_n_1
                      });
                    }
                  } else {
                    // Sinon, remplacer les valeurs de `ca` après `endMonth` par 0
                    totalClient.mois.push({
                      mois: index + 1,
                      ca: index + 1 > endMonth ? 0 : totalMonth.ca,
                      ca_n_1: totalMonth.ca_n_1,
                      ot: index + 1 > endMonth ? 0 : totalMonth.ot,
                      ot_n_1: totalMonth.ot_n_1
                    });
                  }
                });
                
                const totalClients = [];
                totalClients.push(totalClient);
                
                // Mettre à jour les données finales avec les valeurs filtrées
                setChart(totalClients);
                setCityChargData(all3);
                setCityLivrData(all2);
                setLoading(false);
            }else if (clientList.length == 1 && !unClient[0].includes('_PARENT')){
                console.log("1 seul client")
                const startMonth = new Date(dateDu).getMonth() + 1;
                const endMonth = new Date(dateAu).getMonth() + 1;

                let filteredChart;

                if (new Date(dateDu).getFullYear() == new Date(dateAu).getFullYear()) {
                    filteredChart = chart
                      .filter(item => item.codeClient === unClient[0] && item.codeAgence === unClient[2])
                      .map(item => ({
                        ...item,
                        mois: item.mois.filter(moisData => moisData.mois >= startMonth && moisData.mois <= endMonth)
                      }));
                      console.log(filteredChart)
                    console.log("Période équivalente activée");
                  } else {
                    filteredChart = chart
                    .filter(item => item.codeClient === unClient[0] && item.codeAgence === unClient[2])
                        .map(item => ({
                        ...item,
                        mois: item.mois.map((moisData) => {
                            if (moisData.mois > endMonth) {
                            return {
                                ...moisData,
                                ca: 0,
                                ot: 0
                            };
                            }
                            return moisData;
                        })
                    }));

                    console.log("Période étendue activée");
                  }
            
                  setChart(filteredChart);
                  console.log(villeCharg.filter(item => item.idClient === unClient[0] && item.codeAgence === unClient[2]))
                setCityChargData(villeCharg.filter(item => item.idClient === unClient[0] && item.codeAgence === unClient[2]));
                setCityLivrData(villeLivraison.filter(item => item.idClient === unClient[0] && item.codeAgence === unClient[2]));
                setLoading(false); 
            }

        }
        else{
            console.log("plusieurs clients");
            const all = [];
            const all2 = [];
            const all3 = [];

            clientList.forEach(client => {
            let filteredData = chart.filter(item =>
                item.codeClient === client.split(' / ')[0] && item.codeAgence === client.split(' / ')[2]
            );

            // Appliquer la logique du filtre de période
            const startMonth = new Date(dateDu).getMonth() + 1;
            const endMonth = new Date(dateAu).getMonth() + 1;
            

            if (new Date(dateDu).getFullYear() == new Date(dateAu).getFullYear()) {
                filteredData = filteredData.map(item => ({
                ...item,
                mois: item.mois.filter(moisData => moisData.mois >= startMonth && moisData.mois <= endMonth)
                }));
                console.log("Période équivalente activée");
            } else {
                filteredData = filteredData.map(item => ({
                ...item,
                mois: item.mois.map(moisData => {
                    if (moisData.mois > endMonth) {
                    return {
                        ...moisData,
                        ca: 0,
                        ot: 0
                    };
                    }
                    return moisData;
                })
                }));
                console.log("Période étendue activée");
            }

            all.push(...filteredData);

            const filteredData3 = villeCharg.filter(item =>
                item.idClient === client.split(' / ')[0] && item.codeAgence === client.split(' / ')[2]
            );
            all3.push(...filteredData3);

            const filteredData2 = villeLivraison.filter(item =>
                item.idClient === client.split(' / ')[0] && item.codeAgence === client.split(' / ')[2]
            );
            all2.push(...filteredData2);
            });

            const totalClient = {
            cleClient: "TOTAL",
            codeClient: "TOTAL",
            nomClient: "TOTAL",
            mois: []
            };

            const totalsByMonth = [];

            all.forEach(client => {
            client.mois.forEach((moisData, index) => {
                if (!totalsByMonth[index]) {
                totalsByMonth[index] = {
                    ca: 0,
                    ca_n_1: 0,
                    ot: 0,
                    ot_n_1: 0
                };
                }
                totalsByMonth[index].ca += moisData.ca;
                totalsByMonth[index].ca_n_1 += moisData.ca_n_1;
                totalsByMonth[index].ot += moisData.ot;
                totalsByMonth[index].ot_n_1 += moisData.ot_n_1;
            });
            });

                totalsByMonth.forEach((totalMonth, index) => {
                    totalClient.mois.push({
                        mois: index + 1,
                        ca: totalMonth.ca,
                        ca_n_1: totalMonth.ca_n_1,
                        ot: totalMonth.ot,
                        ot_n_1: totalMonth.ot_n_1
                    });
                });

                const totalClients = [];
                totalClients.push(totalClient);

                setChart(totalClients);
                setCityChargData(all3);
                setCityLivrData(all2);
                setLoading(false);
            }
        
            //resetForm();
            //setShowPopup(false)
    
        }

    useEffect(() => {
        if (!loading) {
            // Code qui dépend de loading à false
            if(!loading){

                const doc = new jsPDF("landscape", "mm", "a4");
        
                //page 1
        
                doc.addImage(fonts, 'JPG', 10, 40, 277, 150);
                doc.addImage(hautier, 'JPG', 0, 0, 60, 30);
                doc.line(0, 35, 400, 35)
                    
                doc.setFontSize(28); 
                doc.setTextColor(182, 179, 171);
                doc.setFont("times","italic");
                doc.text("Indicateurs Client", 220, 20)
        
                doc.setFontSize(28);
                doc.setTextColor(0, 0, 0);
                doc.setFont("times","bold");
                if (unClient.length > 0 ) {
                    
                    const textWidth = doc.getStringUnitWidth(unClient[1]) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                const centerX = (doc.internal.pageSize.width - textWidth) / 2;
                
                doc.text(unClient[1], centerX, 100)
                }
                
                doc.setFont("times","");
               /* if (data[0].cpClient !== null && data[0].villeClient !== null) {
                    
                    const textWidth = doc.getStringUnitWidth(data[0].cpClient + " " + data[0].villeClient) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                    const centerX = (doc.internal.pageSize.width - textWidth) / 2;
                    
                    doc.text(data[0].cpClient + " " + data[0].villeClient, centerX, 110)
                }*/
        
                doc.setFontSize(16);
                doc.text("Notre rendez-vous du " + dateFormat(interviewDate, 'dd/mm/yyyy'), 115, 45)
                doc.text("Votre interlocuteur : " + commercial, 108, 55)
                doc.text("Période du "+dateFormat(dateDu, 'dd/mm/yyyy')+ " au "+dateFormat(dateAu, 'dd/mm/yyyy'), 115, 188)
                    
                doc.setFillColor(4, 138, 191);
                doc.rect(0, 200, 400, 10, "F")
                doc.setFontSize(12);
                doc.setTextColor(255, 255, 255);
                doc.text(dateFormat(new Date(), 'dd/mm/yyyy'), 5 , 206)
                doc.text("Groupe Hautier Transports ( c ) - Document confidentiel", 110 , 206)
                doc.text("page 1/5 ", 280 , 206)
        
                html2canvas(chartRef.current).then((canvas) => {
        
                    const imgData = canvas.toDataURL('image/png');
        
                    if (selectedSections.includes("CA / MOIS")) {
        
                        doc.addPage("a4", "landscape");
                        doc.addImage(hautier, 'JPG', 0, 0, 50, 25);
                        doc.line(0, 25, 400, 25)
                                
                        doc.setFontSize(28); 
                        doc.setTextColor(182, 179, 171);
                        doc.setFont("times","italic");
                        doc.text("Evolution du CA par mois", 190, 15)
        
                        doc.setFillColor(4, 138, 191);
                        doc.rect(0, 200, 400, 10, "F")
                        doc.setFontSize(12);
                        doc.setTextColor(255, 255, 255);
        
        
                                    
                        doc.addImage(imgData, 'PNG', 10, 130, 180, 70);
                        console.log(chart[0])
                                
                        const totalCA_N = chart[0].mois.reduce((acc, row) => acc + parseFloat(row.ca || 0), 0);
                        const totalCA_N_1 = chart[0].mois.reduce((acc, row) => acc + parseFloat(row.ca_n_1 || 0), 0);
                        const anneeCur = new Date().getFullYear();
        
                        const tableData = chart[0].mois.map(row => [
                            getMonthName(row.mois),
                            formatNumberWithThousandsSeparator(row.ca.toFixed(2)),
                            formatNumberWithThousandsSeparator(row.ca_n_1.toFixed(2)),
                        ]);
        
                        tableData.push(['Totaux', formatNumberWithThousandsSeparator(totalCA_N.toFixed(2)), formatNumberWithThousandsSeparator(totalCA_N_1.toFixed(2))]);
        
                        const tableOptions = {
                            head: [['Mois', anneeCur.toString(), (anneeCur - 1).toString()]],
                            body: tableData,
                            startY: 26,
                            columnStyles: {
                                0: { columnWidth: 30 },
                                1: { columnWidth: 30 },
                                2: { columnWidth: 30 },
                            },
                            bodyStyles: { maxCellHeight: 10, fontSize: 8 },
                            headStyles: { maxCellHeight: 10, fontSize: 10 },
                            footStyles: {
                                fillColor: [252, 170, 170],
                                fontSize: 10,
                            },
                        };
        
                        autoTable(doc, tableOptions);
        
                        const evolutions = [];
                        for (let i = 0; i < chart[0].mois.length; i++) {
                            const row = chart[0].mois[i];
                            const evolution1 = row.ca - row.ca_n_1;
                            const percentEvolution1 = (evolution1 / row.ca_n_1) * 100;
                            evolutions.push([formatNumberWithThousandsSeparator(evolution1.toFixed(2)) + ' €', percentEvolution1.toFixed(2) + ' %']);
                        }
        
                        const totalEvolution1 = totalCA_N - totalCA_N_1;
                        const totalPercentEvolution1 = (totalEvolution1 / totalCA_N_1) * 100;
        
                        const tableEvol = {
                            head: [[{ content: 'Evolution ' + (anneeCur - 1).toString() + ' > ' + (anneeCur).toString(), colSpan: 2 }]],
                            body: evolutions,
                            foot: [[formatNumberWithThousandsSeparator(totalEvolution1.toFixed(2)) + ' €', totalPercentEvolution1.toFixed(2) + ' %']],
                            startY: 26,
                            margin: { left: 100 },
                            columnStyles: {
                                0: { columnWidth: 30 },
                                1: { columnWidth: 30 },
                            },
                            headStyles: { maxCellHeight: 10, fontSize: 10 },
                            bodyStyles: { maxCellHeight: 10, fontSize: 8 },
                            footStyles: {
                                fillColor: [252, 170, 170],
                                fontSize: 10,
                            },
                        };
        
                        autoTable(doc, tableEvol);
        
                        doc.text(dateFormat(new Date(), 'dd/mm/yyyy'), 5 , 206)
                        doc.text("Groupe Hautier Transports ( c ) - Document confidentiel", 110 , 206)
                        doc.text("page 2/5 ", 280 , 206)
                    }
        
                    html2canvas(chartOTM.current).then((canvas) => {
                        const imgData = canvas.toDataURL('image/png');
        
                        if (selectedSections.includes("NB / MOIS")) {
                            doc.addPage("a4", "landscape");
                            
                            doc.addImage(hautier, 'JPG', 0, 0, 50, 25);
                            doc.line(0, 25, 400, 25)
                            
                            doc.setFontSize(28); 
                            doc.setTextColor(182, 179, 171);
                            doc.setFont("times","italic");
                            doc.text("Nombre de dossiers ( OT ) par mois", 150, 15)
                            doc.setFillColor(4, 138, 191);
                            doc.rect(0, 200, 400, 10, "F")
                            doc.setFontSize(12);
                            doc.setTextColor(255, 255, 255);
                            
                            doc.text(dateFormat(new Date(), 'dd/mm/yyyy'), 5 , 206)
                            doc.text("Groupe Hautier Transports ( c ) - Document confidentiel", 110 , 206)
                            doc.text("page 3/5 ", 280 , 206)
                            
                        
                
                            const totalOT_N = chart[0].mois.reduce((acc, row) => acc + parseFloat(row.ot || 0), 0);
                            const totalOT_N_1 = chart[0].mois.reduce((acc, row) => acc + parseFloat(row.ot_n_1 || 0), 0);
                            const anneeCur = new Date().getFullYear();
                
                            const tableDat = chart[0].mois.map(row => [
                                getMonthName(row.mois),
                                formatNumberWithThousandsSeparator(row.ot),
                                formatNumberWithThousandsSeparator(row.ot_n_1),
                            ]);
                
                            tableDat.push(['Totaux', formatNumberWithThousandsSeparator(totalOT_N.toFixed(2)), formatNumberWithThousandsSeparator(totalOT_N_1.toFixed(2))]);
                
                            const tableOption = {
                                head: [['Mois', anneeCur.toString(), (anneeCur - 1).toString()]],
                                body: tableDat,
                                startY: 26,
                                columnStyles: {
                                    0: { columnWidth: 30 },
                                    1: { columnWidth: 30 },
                                    2: { columnWidth: 30 },
                                },
                                bodyStyles: { maxCellHeight: 10, fontSize: 8 },
                                headStyles: { maxCellHeight: 10, fontSize: 10 },
                                footStyles: {
                                    fillColor: [252, 170, 170],
                                    fontSize: 10,
                                },
                            };
                
                            autoTable(doc, tableOption);
                
                            const evolutions = [];
                            for (let i = 0; i < chart[0].mois.length; i++) {
                                const row = chart[0].mois[i];
                                const evolution = row.ot - row.ot_n_1;
                                const percentEvolution = (evolution / row.ot_n_1) * 100;
                                evolutions.push([evolution, percentEvolution.toFixed(2) + ' %']);
                            }
                
                            const totalEvolution1 = totalOT_N - totalOT_N_1;
                            const percentEvolution = (totalEvolution1 / totalOT_N_1) * 100;
                
                            const tableEvol = {
                                head: [[{ content: 'Evolution ' + (anneeCur - 1).toString() + ' > ' + (anneeCur).toString(), colSpan: 2 }]],
                                body: evolutions,
                                foot: [[totalEvolution1 + '', percentEvolution.toFixed(2) + ' %']],
                                startY: 26,
                                margin: { left: 100 },
                                columnStyles: {
                                    0: { columnWidth: 30 },
                                    1: { columnWidth: 30 },
                                },
                                headStyles: { maxCellHeight: 10, fontSize: 10 },
                                bodyStyles: { maxCellHeight: 10, fontSize: 8 },
                                footStyles: {
                                    fillColor: [252, 170, 170],
                                    fontSize: 10,
                                },
                            };
                
                            autoTable(doc, tableEvol);
                
                            doc.addImage(imgData, 'PNG', 10, 130, 180, 70);
                        }
                    
                        if (selectedSections.includes("VILLES CHARGEMENT / LIVRAISON")) {
                        
                            doc.addPage("a4", "landscape");
                            doc.addImage(hautier, 'JPG', 0, 0, 50, 25);
                            doc.line(0, 25, 400, 25)
                                
                            doc.setFontSize(28); 
                            doc.setTextColor(182, 179, 171);
                            doc.setFont("times","italic");
                            doc.text("Villes de Chargement", 205, 15)
                            doc.setFillColor(4, 138, 191);
                            doc.rect(0, 200, 400, 10, "F")
                            doc.setFontSize(12);
                            doc.setTextColor(255, 255, 255);
                            //footer
                            doc.text(dateFormat(new Date(), 'dd/mm/yyyy'), 5 , 206)
                            doc.text("Groupe Hautier Transports ( c ) - Document confidentiel", 110 , 206)
                            doc.text("page 4/5 ", 280 , 206)
                
                            //body
                            const tableData = [];
                            const tableVillesDisparues = [];
                            const villeData = {};
                            
                            chargement.forEach((item) => {
                                const ville = item.villeDep;
                                const annee = item.annee;
                                const occurences = item.nombreOccurrences;
                            
                                if (!villeData[ville]) {
                                    villeData[ville] = {
                                        ville,
                                        occurences2023: 0,
                                        occurences2022: 0,
                                    };
                                }
                            
                                if (annee === 2024) {
                                    villeData[ville].occurences2023 += occurences;
                                } else if (annee === 2023) {
                                    villeData[ville].occurences2022 += occurences;
                                }
                            });
                
                            Object.values(villeData).forEach((ville) => {
                                const differenceOT = ville.occurences2023 - ville.occurences2022;
                
                            
                                if (ville.occurences2023 !== 0) {
                                    tableData.push([ville.ville, ville.occurences2023, differenceOT]);
                                } else {
                                    tableVillesDisparues.push([ville.ville, differenceOT]);
                                }
                            });
                            tableData.sort((a, b) => b[1] - a[1]);
                            const top25V = tableData.slice(0, 25);
                
                            tableVillesDisparues.sort((a, b) => b[1] - a[1]);
                            const top25VDisp = tableVillesDisparues.slice(0, 25);
                
                            doc.setFontSize(12);
                            doc.setTextColor(0, 0, 0);
                            doc.text("VILLES DE CHARGEMENT SUR LA PERIODE SELECTIONNEE", 10, 30)
                
                            doc.autoTable({
                                head: [["VILLE DE CHARGEMENT", "NOMBRE", "COMPAR. / N-1"]],
                                body: top25V,
                                startY: 32, 
                                columnStyles: {
                                    0: { columnWidth: 50 },
                                    1: { columnWidth: 30 },
                                    2: { columnWidth: 30 },
                                },
                                bodyStyles: { maxCellHeight: 10, fontSize: 6 },
                                headStyles: { maxCellHeight: 10, fontSize: 10 },
                            });
                            doc.text("VILLES DE CHARGEMENT DISPARUES", 150, 30)
                            doc.autoTable({
                                head: [["VILLE DE CHARGEMENT", "COMPAR. / N-1"]],
                                body: top25VDisp,
                                startY: 32, 
                                margin: { left: 150 },
                                columnStyles: {
                                    0: { columnWidth: 50 },
                                    1: { columnWidth: 30 },
                                },
                                bodyStyles: { maxCellHeight: 10, fontSize: 6 },
                                headStyles: { maxCellHeight: 10, fontSize: 10 },
                            });
                
                            //header
                            doc.addPage("a4", "landscape");
                            doc.addImage(hautier, 'JPG', 0, 0, 50, 25);
                            doc.line(0, 25, 400, 25)
                                    
                            doc.setFontSize(28); 
                            doc.setTextColor(182, 179, 171);
                            doc.setFont("times","italic");
                            doc.text("Villes de Livraison", 205, 15)
                            doc.setFillColor(4, 138, 191);
                            doc.rect(0, 200, 400, 10, "F")
                            doc.setFontSize(12);
                            doc.setTextColor(255, 255, 255);
                            //footer
                            doc.text(dateFormat(new Date(), 'dd/mm/yyyy'), 5 , 206)
                            doc.text("Groupe Hautier Transports ( c ) - Document confidentiel", 110 , 206)
                            doc.text("page 5/5 ", 280 , 206)
                    
                            //body
                            const tableData2 = [];
                            const tableVillesDisparues2 = [];
                            const villeData2 = {};
                            
                            livraison.forEach((item) => {
                                const ville = item.villeDep;
                                const annee = item.annee;
                                const occurences = item.nombreOccurrences;
                            
                                if (!villeData2[ville]) {
                                    villeData2[ville] = {
                                        ville,
                                        occurences2023: 0,
                                        occurences2022: 0,
                                    };
                                }
                            
                                if (annee === 2024) {
                                    villeData2[ville].occurences2023 += occurences;
                                } else if (annee === 2023) {
                                    villeData2[ville].occurences2022 += occurences;
                                }
                            });
                
                            Object.values(villeData2).forEach((ville) => {
                                const differenceOT = ville.occurences2023 - ville.occurences2022;
                
                            
                                if (ville.occurences2023 !== 0) {
                                    tableData2.push([ville.ville, ville.occurences2023, differenceOT]);
                                } else {
                                    tableVillesDisparues2.push([ville.ville, differenceOT]);
                                }
                            });
                            tableData2.sort((a, b) => b[1] - a[1]);
                            const top25Villes = tableData2.slice(0, 25);
                
                            tableVillesDisparues2.sort((a, b) => b[1] - a[1]);
                            const top25VillesDisparu = tableVillesDisparues2.slice(0, 25);
                
                            doc.setFontSize(12);
                            doc.setTextColor(0, 0, 0);
                            doc.text("VILLES DE LIVRAISON SUR LA PERIODE SELECTIONNEE", 10, 30)
                
                            doc.autoTable({
                                head: [["VILLE DE LIVRAISON", "NOMBRE", "COMPAR. / N-1"]],
                                body: top25Villes,
                                startY: 32, 
                                columnStyles: {
                                    0: { columnWidth: 50 },
                                    1: { columnWidth: 30 },
                                    2: { columnWidth: 30 },
                                },
                                bodyStyles: { maxCellHeight: 10, fontSize: 6 },
                                headStyles: { maxCellHeight: 10, fontSize: 10 },
                            });
                            doc.text("VILLES DE LIVRAISON DISPARUES", 150, 30)
                            doc.autoTable({
                                head: [["VILLE DE LIVRAISON", "COMPAR. / N-1"]],
                                body: top25VillesDisparu,
                                startY: 32, 
                                margin: { left: 150 },
                                columnStyles: {
                                    0: { columnWidth: 50 },
                                    1: { columnWidth: 30 },
                                },
                                bodyStyles: { maxCellHeight: 10, fontSize: 6 },
                                headStyles: { maxCellHeight: 10, fontSize: 10 },
                            });
                        }
            
                        doc.save('RevueClient.pdf');
                    })
        
                    
                });
            }
        }
    }, [loading]);

    const addToClientList = () => {

        if (searchTerm.trim() !== '') {
            console.log(searchTerm)
            setClientList([...clientList, searchTerm.trim()]);
            setSearchTerm('');
        }
    };

    const remToClientList = (index) => {
        setClientList(clientList.filter((_, i) => i !== index));
       
    };


    const handleToggleSection = (section) => {
        if (selectedSections.includes(section)) {
            setSelectedSections(selectedSections.filter((s) => s !== section));
        } else {
            setSelectedSections([...selectedSections, section]);
        }
    };
    const handleCommercialChange = (e) => {
        setCommercial(e.target.value);
    };
    const handleSearchChange = (e) => {
        e.preventDefault();
        setSearchTerm(e.target.value);
        
    };



    return (
        <>
            
                <div>
                    <button className='bg-vertLogo w-full flex items-center rounded text-blanc justify-center group hover:border hover:border-vertLogo hover:bg-blanc hover:text-vertLogo' onClick={() => setShowPopup(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-8 h-8 group-hover:stroke-vertLogo">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
                        </svg>
                        <label>Télécharger la revue</label>
                    </button>
                    {showPopup && (
                        <div className="fixed inset-0 flex items-center justify-center z-50 ">
                        <div className="bg-blanc w-[50%] p-4 rounded-lg shadow-lg border border-marineLogo min-h-[20%] max-h-[100%] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100">
                            <div className="bg-white p-8 rounded-lg">
                                <h2 className="text-lg font-bold mb-4 text-marineLogo">Télécharger la revue</h2>
                                <fieldset className="border border-gray-300 p-4 rounded-lg bg-bleuBlanc">
                                <legend className="text-lg mb-2 text-marineLogo">CRITERES D'ANALYSE</legend>
                                <div className="w-full">
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="periode" className="block text-sm font-medium text-grey">Période :</label>
                                    <div className="flex justify-between w-full ml-4 mr-4">
                                        <div className='flex flex-col w-[45%]'>
                                            <label className=' text-grey' htmlFor="dateDu">Du</label>
                                            <input
                                                type="date"
                                                id="dateDu"
                                                name="dateDu"
                                                value={dateDu}
                                                onChange={(e) => setDateDu(e.target.value)}
                                                className="mt-1 block w-full rounded-md border border-gray-300 focus:outline-none focus:border-vertLogo px-3"
                                            />
                                        </div>
                                        <div className='flex flex-col w-[45%] mr-4'>
                                            <label className=' text-grey' htmlFor="dateAu">Au</label>
                                            <input
                                                type="date"
                                                id="dateAu"
                                                name="dateAu"
                                                value={dateAu}
                                                onChange={(e) => setDateAu(e.target.value)}
                                                className="mt-1 block w-full rounded-md border border-gray-300 focus:outline-none focus:border-vertLogo px-3"
                                            />
                                        </div>
                                    </div>
                                    {/*<div className="flex items-center mt-4">
                                        <input
                                        type="checkbox"
                                        id="periodeEquivalente"
                                        name="periodeEquivalente"
                                        checked={periodeEquivalente}  // lié à l'état
                                        onChange={(e) => setPeriodeEquivalente(e.target.checked)}
                                        className="border border-marineLogo text-sm rounded mr-2"
                                        />
                                        <label htmlFor="periodeEquivalente">Période équivalente</label>
                                    </div>*/}
                                </div>
                                <div className="mt-4">
                                    <label htmlFor="client" className="block text-sm font-medium text-grey">Liste des clients à inclure dans la présentation :</label>
                                    <div className="flex items-center ml-4">
                                        
                                        <input
                                            type='text'
                                            list='clientsList'
                                            name='clientsList'
                                            id='simple-search'
                                            className=' w-[90%]  border bg-blanc border-marineLogo rounded group-hover:ring-vertLogo group-hover:border-vertLogo block '
                                            placeholder='Nom client'
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            
                                        />
                                        <datalist id='clientsList'>
                                            {client.map(c => (
                                                <option key={c.codeClient + ' / ' + c.codeAgence} value={c.codeClient + ' / ' + c.nomClient + ' / ' + c.codeAgence}>{c.codeClient} {c.nomClient} {c.codeAgence}</option>
                                            ))}
                                        </datalist>
                                        
                                        <button
                                            type="button"
                                            className="ml-2 px-3 py-1 bg-blue-500 border border-marineLogo text-marineLogo rounded-md hover:bg-vertLogo hover:text-blanc hover:border hover:border-vertLogo focus:outline-none"
                                            onClick={addToClientList}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>
                                {clientList.length > 0 && (
                                    <div className="mb-4">
                                        <ul>
                                            {clientList.map((client, index) => (
                                            <li key={index}>
                                                {client} 
                                                <button
                                                    type="button"
                                                    className="ml-2 px-3 py-1 border border-marineLogo text-marineLogo rounded-md hover:bg-roseLogo hover:text-blanc hover:border hover:border-roseLogo focus:outline-none"
                                                    onClick={() => remToClientList(index)}
                                                >
                                                    -
                                                </button>
                                            
                                            </li>
                                            ))}
                                        </ul>
                                        </div>
                                )}
                            </fieldset>
                            <fieldset className="border border-gray-300 p-4 rounded-lg mt-4 bg-bleuBlanc">
                                <legend className="text-lg mb-2 text-marineLogo">RUBRIQUES A INCLURE DANS LA PRESENTATION</legend>
                                <ul>
                                    <li>
                                        <input
                                            type="checkbox"
                                            id="page2"
                                            name="page2"
                                            checked={selectedSections.includes("CA / MOIS")}
                                            onChange={() => handleToggleSection("CA / MOIS")}
                                        />
                                        <label className='ml-2' htmlFor="page2">CA / MOIS</label>
                                    </li>
                                    <li>
                                        <span className='text-sm italic text-grey'>Chiffre d'affaires par mois ( sur l'année civile + évolution )</span>
                                    </li>
                                    <li>
                                        <input
                                            type="checkbox"
                                            id="page3"
                                            name="page3"
                                            checked={selectedSections.includes("NB / MOIS")}
                                            onChange={() => handleToggleSection("NB / MOIS")}
                                        />
                                        <label className='ml-2' htmlFor="page3">NB / MOIS</label>

                                    </li>
                                    <li>
                                        <span className='text-sm italic text-grey'>Nombre de dossiers ( ordres de transport ) réalisés par mois sur l'année civile</span>
                                    </li>
                                    <li>
                                        <input
                                            type="checkbox"
                                            id="page4_5"
                                            name="page4_5"
                                            checked={selectedSections.includes("VILLES CHARGEMENT / LIVRAISON")}
                                            onChange={() => handleToggleSection("VILLES CHARGEMENT / LIVRAISON")}
                                        />
                                        <label className='ml-2' htmlFor="page4_5">VILLES CHARGEMENT / LIVRAISON</label>
                                    </li>
                                    <li>
                                        <span className='text-sm italic text-grey'>Top 20 des villes de chargement et livraison pour la période sélectionnée</span>
                                    </li>
                                </ul>
                            </fieldset>

                            
                            <fieldset className="border border-gray-300 p-4 rounded-lg mt-4 bg-bleuBlanc">
                                <legend className="text-lg mb-2 text-marineLogo">INFORMATIONS DIVERSES</legend>
                                <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label htmlFor="interviewDate" className="block text-sm font-medium text-gray-700">Date de notre entrevue :</label>
                                    <input type="date" id="interviewDate" name="interviewDate" value={interviewDate} onChange={(e) => setInterviewDate(e.target.value)} className="mt-1 block w-full rounded-md border border-gray-300 focus:outline-none focus:border-vertLogo px-3" />
                                </div>
                                <div>
                                    <label htmlFor="commercialName" className="block text-sm font-medium text-gray-700">Commercial :</label>
                                    <select
                                            id="commercial-select"
                                            className="border border-marineLogo bg-blanc text-sm rounded p-1 w-full"
                                            value={commercial}
                                            placeholder="Commercial"
                                            onChange={handleCommercialChange}
                                        >
                                            <option value=""></option>
                                            {teamData.map(t => (
                                                <option key={t.idUser} value={t.name+' '+t.firstName}>{t.name} {t.firstName}</option>
                                            ))}
                                        </select>
                                </div>
                            </div>
                            </fieldset>
                            <div className="flex justify-end mt-6">
                                <button onClick={generatePDF} className="bg-vertLogo text-blanc border border-vertLogo px-4 py-2 rounded-lg hover:bg-blanc hover:text-vertLogo mr-4">Générer PDF</button>
                                <button onClick={() => {setShowPopup(false); resetForm(); }} className="bg-roseLogo text-blanc border border-roseLogo px-4 py-2 rounded-lg hover:bg-blanc hover:text-roseLogo">Annuler</button>
                            </div>
                            </div>
                        </div>
                    </div>
                    )}
                    <div style={{ position: 'absolute', zIndex: '20', left: '-10000px', top: '-10000px', width: '650px', height: '400px' }} ref={chartRef}>
                        {clientList.length == 1 ? (
                            <GraphCAM className="w-[80%] h-[60%]" client={clientList[0].split(' / ')[0]} agence={clientList[0].split(' / ')[2]} dateDu={dateDu} 
                            dateAu={dateAu} 
                            periodeEquivalente={periodeEquivalente} />
                        ) : (
                            <GraphCAM className="w-[80%] h-[60%]" clientList={clientList}  dateDu={dateDu} 
                            dateAu={dateAu} 
                            periodeEquivalente={periodeEquivalente} />
                        )}
                        
                        
                    </div>
                    <div style={{ position: 'absolute', zIndex: '20', left: '-10000px', top: '-10000px', width: '650px', height: '400px' }} ref={chartOTM}>
                        {clientList.length == 1 ? (
                            <GraphOTM className="w-[80%] h-[60%]" client={clientList[0].split(' / ')[0]} agence={clientList[0].split(' / ')[2]}  dateDu={dateDu} 
                            dateAu={dateAu} 
                            periodeEquivalente={periodeEquivalente} />
                        ) : (
                            <GraphOTM className="w-[80%] h-[60%]" clientList={clientList}  dateDu={dateDu} 
                            dateAu={dateAu} 
                            periodeEquivalente={periodeEquivalente} />
                        )}
                        
                    </div>
                </div>
        </>
    );
}

export default GenPDF;