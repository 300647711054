import React, { useState, useEffect } from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import {Link} from "react-router-dom";
import axios from "axios";

import FormRemoveProsp from "../Containers/formulaire/FormRemoveProsp"

function EnableProspect({ url }) {

    const [prospectData, setProspectData] = useState([]);
    const json = localStorage.getItem("token");
    const userInfo = JSON.parse(json);
    const [submitData, setSubmitData] = useState([]);

    const handleSubmit = (submitFilterData) => {
        setSubmitData(submitFilterData)
        getProspect(submitFilterData);

    };

    useEffect(() => {
        
        getProspect(submitData);
    }, [url]);

    function getProspect(submitData){
        axios.get(url+'client/prospect', {
            params: {
                searchTerm: submitData.searchTerm,
                commercial: submitData.commercial,
                selectedAgence: submitData.selectedAgence
            },
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {       
            setProspectData(res.data);
        })
        .catch(error=>{
            console.log(error);
            
        });
    }

    function onSubmitSuccess (){
        getProspect(submitData);
      }

    console.log(prospectData)
    
    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Désactiver prospect" btn={false}  onSubmitFilter={handleSubmit} />
                </div>
                <div className="p-2 h-[85%] w-full flex flex-row">
                    <div className='w-[80%] h-full font-bahn'>
                        <div className='h-[4%] w-full'>
                            <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[80%] border-collapse ">
                                <thead className='h-[6%] max-h-1.5'>
                                    <tr className='bg-marineLogo text-blanc'>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Commercial</th>
                                        <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Prospect</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div className='h-[80%] w-full overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>                
                            <table className="table-fixed h-[96%] border-collapse items-center bg-transparent w-[80%]">
                                <tbody className=' overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-[94%] min-h-[50%]'>
                                    {prospectData.map((prospect, index) => (
                                        <tr key={index} className='max-h-[10%] min-h-[5%]'>
                                            <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">{prospect.name} {prospect.firstName}</td>
                                            <Link to={"/profil/client/" + prospect.codeClient + "/" + prospect.codeAgence}>
                                                <td className="whitespace-nowrap text-xs p-2">{prospect.nomClient}</td>
                                            </Link>
                                            
                                        </tr>
                                    ))}
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                    <div className='w-[20%] h-full flex justify-end'>
                        <FormRemoveProsp url={url} token={userInfo.token} onSubmitSuccess={onSubmitSuccess}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EnableProspect;