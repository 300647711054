export const msalConfig = {
    auth: {
      clientId: "3ad505e0-cfd5-4cbe-a181-93723707f155",
      authority: "https://login.microsoftonline.com/{ac300d71-257e-425d-90d2-b5b13bd2308d}", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://crm.hautier-transports.fr/",
     //redirectUri: "http://localhost/",
    },
    cache: {
      cacheLocation: "localStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read", "Calendars.ReadWrite"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 2);
const formattedDate = currentDate.toISOString().slice(0, 19).replace("T", " ");

currentDate.setFullYear(currentDate.getFullYear() + 1);
const formattedDate2 = currentDate.toISOString().slice(0, 19).replace("T", " ");
  
  export const graphConfig = {
      graphMyCalendarGet: "https://graph.microsoft.com/v1.0/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location&$filter=start/dateTime ge '"+formattedDate+"'and start/dateTime le '"+formattedDate2+"'&top=2000",
      graphMyCalendarPost: "https://graph.microsoft.com/v1.0/me/events",
      graphMe: "https://graph.microsoft.com/v1.0/me"
  };

